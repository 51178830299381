import React from 'react';
import './PermissionOverview.css';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationModel, PermissionModel } from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  applicationStore,
  loadOnePermission,
  selectedPermissionsStore,
} from '../../stores';
import { useEffect } from 'react';

export const PermissionOverview = () => {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  if (!params.permissionId) throw new Error('Permission is required');
  const permissionId = parseInt(params.permissionId);

  const permission: PermissionModel | null = useRecoilValue(
    selectedPermissionsStore,
  );
  const setRecoilPermission: SetterOrUpdater<PermissionModel | null> =
    useSetRecoilState(selectedPermissionsStore);

  useEffect(() => {
    if (!permission)
      loadOnePermission(
        getAccessTokenSilently,
        application,
        setRecoilPermission,
        permissionId,
      );
  }, [permission]);

  return <div>PermissionOverview</div>;
};
