import React from 'react';
import './ListingPage.css';
import { PageWithSideBar } from '../PageWithSideBar';
import { DataTable, DataTableActions } from '../DataTable';
import {
  ApplicationModel,
  PermissionModel,
  RoleModel,
  searchAllUsersPaginated,
  UserModel,
  UserWhoiamDto,
} from '../../api';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  applicationsStore,
  applicationStore,
  permissionsStore,
  rolesStore,
  selectedPermissionsStore,
  selectedRoleStore,
  selectedUserStore,
  usersStore,
  userStore,
} from '../../stores';
import { goto } from '../../helpers';
import { Tooltip } from '@mui/material';
import { EntityTypes } from '../../types';

export interface ListingPageProps {
  entityType: EntityTypes;
}

export const ListingPage = (props: ListingPageProps) => {
  const role: RoleModel | null = useRecoilValue(selectedRoleStore);
  const setApplication: CallableFunction = useSetRecoilState(applicationStore);
  const setSelectedUser: CallableFunction =
    useSetRecoilState(selectedUserStore);
  const setSelectedRole: CallableFunction =
    useSetRecoilState(selectedRoleStore);
  const setSelectedPermission: CallableFunction | null = useSetRecoilState(
    selectedPermissionsStore,
  );
  const dashboardUser: UserWhoiamDto | null = useRecoilValue(userStore);
  const users: UserModel[] = useRecoilValue(usersStore);
  const applications: ApplicationModel[] = useRecoilValue(applicationsStore);
  const roles: RoleModel[] = useRecoilValue(rolesStore);
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const permissions: PermissionModel[] = useRecoilValue(permissionsStore);

  if (props.entityType === 'application') {
    const actions: DataTableActions<ApplicationModel>[] = [];

    actions.push({
      label: 'Manage',
      onclick: (app: ApplicationModel) => {
        goto(`/application/${app.id}`);
      },
    });

    if (dashboardUser?.isDashboardAdmin) {
      actions.push({
        label: 'Edit',
        onclick: (app: ApplicationModel) => {
          goto(`/application/${app.id}/edit`);
        },
      });
      actions.push({
        label: 'Delete',
        onclick: (app: ApplicationModel) => {
          goto(`/application/${app.id}/delete`);
        },
      });
    }

    return (
      <DataTable<ApplicationModel>
        addOnclick={
          dashboardUser?.isDashboardAdmin
            ? () => {
                setApplication(null);
                goto('/application/create');
              }
            : undefined
        }
        renderers={[
          {
            fieldName: 'administratorsEmails',
            renderer: (app: ApplicationModel) =>
              app.administratorsEmails.map((auth0UserId: string) => {
                if (auth0UserId.length > 10)
                  return (
                    <div>
                      <Tooltip title={auth0UserId} placement="left">
                        <span
                          style={{
                            overflow: 'hidden',
                            display: 'inline-block',
                            width: '350px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {auth0UserId}
                        </span>
                      </Tooltip>
                    </div>
                  );
                return <div key={`${auth0UserId}-xx`}>{auth0UserId}</div>;
              }),
          },
        ]}
        data={applications}
        actions={actions}
        columnsToShow={
          dashboardUser?.isDashboardAdmin
            ? ['id', 'name', 'clientId', 'resourceId', 'administratorsEmails']
            : ['id', 'name']
        }
      />
    );
  } else if (props.entityType === 'user') {
    return (
      <PageWithSideBar
        links={[
          {
            active: true,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: false,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: false,
            label: 'Permissions',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <DataTable<UserModel>
          renderers={[
            {
              fieldName: 'roles',
              renderer: (user: UserModel) =>
                user.roles.map((role: RoleModel) => role.name).join(', '),
            },
          ]}
          hasPagination={true}
          data={users}
          searchFieldHandler={searchAllUsersPaginated}
          addOnclick={() => {
            setSelectedUser(null);
            goto(`/application/${application?.id}/user/create`);
          }}
          actions={[
            // { label: 'View', link: (user: UserModel) => `/application/${application?.id}/user/${user.id}` },
            {
              label: 'Edit',
              onclick: (user: UserModel) => {
                // loadUser(getAccessTokenSilently, user.id);
                goto(`/application/${application?.id}/user/${user.id}/edit`);
              },
              showStatementCondition: () => {
                return true;
                // return user.email !== dashboardUser?.email;
              },
            },
            {
              label: 'Delete',
              onclick: (user: UserModel) => {
                // loadUser(getAccessTokenSilently, user.id);
                goto(`/application/${application?.id}/user/${user.id}/delete`);
              },
              showStatementCondition: (user: UserModel) => {
                return user.email !== dashboardUser?.email;
              },
            },
          ]}
          columnsToShow={['id', 'auth0Id', 'email', 'roles']}
        />
      </PageWithSideBar>
    );
  } else if (props.entityType === 'role') {
    return (
      <PageWithSideBar
        links={[
          {
            active: false,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: true,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: false,
            label: 'Permissions',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <DataTable<RoleModel>
          addOnclick={() => {
            setSelectedRole(null);
            goto(`/application/${application?.id}/role/create`);
          }}
          data={roles}
          actions={[
            {
              label: 'Permissions',
              onclick: (role: RoleModel) => {
                goto(
                  `/application/${application?.id}/role/${role.id}/permissions`,
                );
              },
            },
            {
              label: 'Edit',
              onclick: (role: RoleModel) => {
                goto(`/application/${application?.id}/role/${role.id}/edit`);
              },
              showStatementCondition: (role: RoleModel) => {
                return role.name !== 'admin' && role.name !== 'user';
              },
            },
            {
              label: 'Delete',
              onclick: (role: RoleModel) => {
                goto(`/application/${application?.id}/role/${role.id}/delete`);
              },
              showStatementCondition: (role: RoleModel) => {
                return role.name !== 'admin' && role.name !== 'user';
              },
            },
          ]}
          columnsToShow={['id', 'name', 'description']}
        />
      </PageWithSideBar>
    );
  } else if (props.entityType === 'permission') {
    let permissionsList: PermissionModel[] = [];
    if (role) {
      permissionsList = role.permissions;
    }
    return (
      <PageWithSideBar
        links={
          roles.length === 0
            ? []
            : roles.map((_role: RoleModel) => {
                return {
                  active: _role.id === role?.id,
                  label: _role.name,
                  link: `/application/${application?.id}/role/${_role.id}/permissions`,
                };
              })
        }
      >
        <DataTable<PermissionModel>
          addOnclick={() => {
            setSelectedPermission(null);
            goto(
              `/application/${application?.id}/role/${role?.id}/permission/create`,
            );
          }}
          data={permissionsList}
          renderers={[
            {
              fieldName: 'roles',
              renderer: (perm: PermissionModel) => {
                // console.log({perm});
                if (!perm.roles) return '';
                return perm.roles
                  .map((role: RoleModel) => role.name)
                  .join(', ');
              },
            },
          ]}
          actions={[
            /*{
                  label: 'View', onclick: (perm: PermissionModel) => {
                    goto(`/application/${application?.id}/role/${role?.id}/permission/${perm.id}`)
                  }
                },*/
            {
              label: 'Edit',
              onclick: (perm: PermissionModel) => {
                goto(
                  `/application/${application?.id}/role/${role?.id}/permission/${perm.id}/edit`,
                );
              },
            },
            {
              label: 'Delete',
              onclick: (perm: PermissionModel) => {
                goto(
                  `/application/${application?.id}/role/${role?.id}/permission/${perm.id}/delete`,
                );
              },
            },
          ]}
          columnsToShow={['id', 'name', 'description', 'roles']}
        />
      </PageWithSideBar>
    );
  } else if (props.entityType === 'applicationPermissions') {
    let permissionsList: PermissionModel[] = [];
    if (permissions && permissions.length > 0) {
      permissionsList = permissions;
    }

    return (
      <PageWithSideBar
        links={[
          {
            active: false,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: false,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: true,
            label: 'Permissions',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <DataTable<PermissionModel>
          addOnclick={() => {
            setSelectedPermission(null);
            goto(`/application/${application?.id}/permission/create`);
          }}
          renderers={[
            {
              fieldName: 'roles',
              renderer: (perm: PermissionModel) => {
                // console.log({perm});
                if (!perm.roles) return '';
                return perm.roles
                  .map((role: RoleModel) => role.name)
                  .join(', ');
              },
            },
          ]}
          data={permissionsList}
          actions={[
            /*{
                  label: 'View', onclick: (perm: PermissionModel) => {
                    goto(`/application/${application?.id}/role/${role?.id}/permission/${perm.id}`)
                  }
                },*/
            {
              label: 'Edit',
              onclick: (perm: PermissionModel) => {
                goto(
                  `/application/${application?.id}/permission/${perm.id}/edit`,
                );
              },
            },
            {
              label: 'Delete',
              onclick: (perm: PermissionModel) => {
                goto(
                  `/application/${application?.id}/permission/${perm.id}/delete`,
                );
              },
            },
          ]}
          columnsToShow={['id', 'name', 'description', 'roles']}
        />
      </PageWithSideBar>
    );
  } else {
    return <div>Invalid entity type</div>;
  }
};
