import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export class ApplicationFormModel {
  clientId: string;
  ressourceId: string;
  administratorsEmails: string[] = [];
  administratorsEmailsError = '';
}

export const defaultApplicationFormModel: ApplicationFormModel = {
  clientId: '',
  ressourceId: '',
  administratorsEmails: [],
  administratorsEmailsError: '',
};

export const applicationFormStore: RecoilState<ApplicationFormModel> =
  atom<ApplicationFormModel>({
    key: 'applicationFormStore',
    default: defaultApplicationFormModel,
  });

export const setApplicationFormStoreClientId = (
  currentState: ApplicationFormModel,
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
  clientId: string,
) => {
  setRecoil({ ...currentState, clientId });
};

export const setApplicationFormStoreRessourceId = (
  currentState: ApplicationFormModel,
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
  ressourceId: string,
) => {
  setRecoil({ ...currentState, ressourceId });
};

export const setApplicationFormStoreAdministratorsEmails = (
  currentState: ApplicationFormModel,
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
  administratorsEmails: string[],
) => {
  setRecoil({ ...currentState, administratorsEmails });
};

export const setApplicationFormStoreAdministratorsEmailError = (
  currentState: ApplicationFormModel,
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
  administratorsEmailsError: string,
) => {
  setRecoil({ ...currentState, administratorsEmailsError });
};

export const resetApplicationFormStore = (
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
) => {
  setRecoil(defaultApplicationFormModel);
};

export const setApplicationFormStoreValues = (
  setRecoil: SetterOrUpdater<ApplicationFormModel>,
  values: ApplicationFormModel,
) => {
  setRecoil(values);
};
