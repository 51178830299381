import React from 'react';
import './DataTable.css';
import {
  Button,
  Input,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { buttonStyle, buttonStyleTableAction } from '../../styles';
import {
  applicationStore,
  paginationStore,
  PaginationStoreModel,
  perPage,
  searchStore,
} from '../../stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { goto } from '../../helpers';
import { ApplicationModel } from '../../api';
import { plainToInstance } from 'class-transformer';
import { useAuth0 } from '@auth0/auth0-react';

export interface DataTableActions<T> {
  label: string;
  onclick: (item: T) => void;
  showStatementCondition?: (item: T) => boolean;
}

export interface DataTableRendere<T> {
  fieldName: string;
  renderer: (item: T) => string | React.ReactNode;
}

export interface DataTableProps<T> {
  data: T[];
  renderers?: DataTableRendere<T>[];
  actions: DataTableActions<T>[];
  columnsToShow?: string[];
  addOnclick?: () => void;
  hasPagination?: boolean;
  searchFieldHandler?: CallableFunction;
}

export function DataTable<T>(props: DataTableProps<T>) {
  const { getAccessTokenSilently } = useAuth0();
  const currentPagination: PaginationStoreModel =
    useRecoilValue(paginationStore);
  const setRecoilPagination: SetterOrUpdater<PaginationStoreModel> =
    useSetRecoilState(paginationStore);
  const application: ApplicationModel | null = useRecoilValue(applicationStore);

  const q: string = useRecoilValue(searchStore);
  const setQ: SetterOrUpdater<string> = useSetRecoilState(searchStore);

  const pageCount = Math.ceil(currentPagination.total / perPage);

  let data: T[] = props.data;
  let keys: string[] = [];
  const actions: DataTableActions<T>[] = props.actions;
  const columnsToShow = props.columnsToShow;

  let firstItem: T | undefined = undefined;

  if (data[0] !== undefined) {
    firstItem = data[0];
    keys = Object.keys(firstItem);

    if (columnsToShow) {
      data = data.map((item: T) => {
        const newItem: any = {};
        columnsToShow.forEach((columnToShow: string) => {
          newItem[columnToShow] = item[columnToShow];
        });
        return newItem;
      });

      keys = columnsToShow;
    }
  }

  let tableContent = <></>;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    if (!application) return;
    setRecoilPagination(
      plainToInstance(PaginationStoreModel, {
        page: page,
        total: currentPagination.total,
      }),
    );
    if (q.length > 1) {
      goto(`/application/${application.id}/users/${q}/${page}`);
    } else {
      goto(`/application/${application.id}/users/${page}`);
    }
  };

  const search = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!application) return;
    if (!getAccessTokenSilently) return;
    if (!props.searchFieldHandler) return;
    if (!event.target.value) return;
    if (event.target.value.length < 2) {
      setQ('');
      return goto(`/application/${application.id}/users/1`);
    } else {
      goto(
        `/application/${application.id}/users/${encodeURI(
          event.target.value,
        )}/${currentPagination.page}`,
      );
    }
  };

  if (data.length === 0) {
    tableContent = <></>;
  } else {
    tableContent = (
      <TableContainer
        component={Paper}
        style={{ marginTop: 10 }}
        className="DataTable"
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {keys.map((key: string, idx: number) => (
                <TableCell key={`header_key_${idx}`}>{String(key)}</TableCell>
              ))}
              {actions &&
                actions.map((action: DataTableActions<T>, idx: number) => (
                  <TableCell key={`header_action_${idx}`}></TableCell>
                ))}
            </TableRow>
          </TableHead>
          <tbody>
            {data.map((item: T, idx: number) => (
              <TableRow key={`row_${idx}`}>
                {keys.map((key: string, idx: number) => {
                  if (
                    props.renderers !== undefined &&
                    props.renderers.find(
                      (renderer: DataTableRendere<T>) =>
                        renderer.fieldName === key,
                    ) !== undefined
                  ) {
                    const renderer = props.renderers.find(
                      (renderer: DataTableRendere<T>) =>
                        renderer.fieldName === key,
                    );
                    if (renderer !== undefined) {
                      return (
                        <TableCell
                          key={`cell_${key}_${idx}`}
                          style={{ verticalAlign: 'top' }}
                          valign={'top'}
                        >
                          {renderer?.renderer(item)}
                        </TableCell>
                      );
                    }
                  }
                  return (
                    <TableCell
                      style={{ verticalAlign: 'top' }}
                      valign={'top'}
                      key={`cell_${key}_${idx}`}
                    >
                      {typeof item[key] === 'object'
                        ? JSON.stringify(item[key])
                        : String(item[key])}
                    </TableCell>
                  );
                })}
                <TableCell
                  key={`table-actions`}
                  style={{ verticalAlign: 'top' }}
                  valign={'top'}
                >
                  {actions &&
                    actions.map(
                      (action: DataTableActions<T>, actionIdx: number) => {
                        if (
                          action.showStatementCondition !== undefined &&
                          !action.showStatementCondition(item)
                        ) {
                          return <></>;
                        }
                        return (
                          <Button
                            key={`action_${actionIdx}_${idx}`}
                            variant="outlined"
                            style={buttonStyleTableAction}
                            onClick={() => {
                              action.onclick(item);
                            }}
                            className="btn btn-primary"
                          >
                            {action.label}
                          </Button>
                        );
                      },
                    )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        {props.hasPagination && pageCount >= 1 ? (
          <div className={'pagination'}>
            <Pagination
              count={pageCount}
              page={currentPagination.page}
              onChange={handleChangePage}
            />
          </div>
        ) : (
          <></>
        )}
      </TableContainer>
    );
  }

  return (
    <div>
      {props.addOnclick === undefined ? (
        <></>
      ) : (
        <Button
          variant="outlined"
          style={buttonStyle}
          onClick={() => {
            props.addOnclick?.();
          }}
          className="btn btn-primary"
        >
          Add
        </Button>
      )}
      {props.searchFieldHandler === undefined ? (
        <></>
      ) : (
        <Input
          placeholder={'Search'}
          style={{
            marginLeft: 10,
          }}
          onChange={search}
          className="btn btn-primary"
        />
      )}
      {tableContent}
    </div>
  );
}
