import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';
import { createTheme, ThemeProvider } from '@mui/material';
import { audience, clientId, domain, scope } from './constants';

const theme = createTheme({
  palette: {
    primary: {
      main: '#013B8D', // Or any color you want.
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // This is the style rule name for a contained button with the primary color
          backgroundColor: '#013B8D', // Use green color
          '&:hover': {
            backgroundColor: '#013B8D', // Darker green when hovering over it
          },
        },
      },
    },
  },
});

console.log('clientId', clientId);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Auth0Provider
          // useRefreshTokens={true}
          domain={domain as string}
          clientId={clientId as string}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: audience,
            scope: scope,
          }}
        >
          <App />
        </Auth0Provider>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
