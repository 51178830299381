import './UserForm.css';
import {
  ApplicationModel,
  autocomplete,
  createUser,
  removeUser,
  RoleModel,
  updateRoles,
  UserModel,
} from '../../api';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  applicationStore,
  loadUser,
  loadUsers,
  resetUserFormStore,
  rolesStore,
  selectedUserStore,
  sendToaster,
  setUserFormStoreEmailError,
  setUserFormStoreRolesIds,
  ToasterState,
  toasterStore,
  UserFormModel,
  userFormStore,
  usersStore,
} from '../../stores';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { buttonStyle } from '../../styles';
import { router } from '../../router';
import { useAuth0 } from '@auth0/auth0-react';
import { goto, toastErrorFromApiCatch } from '../../helpers';
import { plainToInstance } from 'class-transformer';

export interface UserModelFormProps {
  mode: 'update' | 'delete' | 'create';
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(role: RoleModel, roles: RoleModel[], theme: Theme) {
  return {
    fontWeight:
      roles.findIndex((_role: RoleModel) => _role.id === role.id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const UserForm = (props: UserModelFormProps) => {
  const mode: 'update' | 'delete' | 'create' = props.mode;

  /*HOOKS*/
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const user: UserModel | null = useRecoilValue(selectedUserStore);
  const setRecoilUser: SetterOrUpdater<UserModel | null> =
    useSetRecoilState(selectedUserStore);
  const setRecoilUsers: SetterOrUpdater<UserModel[]> =
    useSetRecoilState(usersStore);
  const setRecoilToaster: SetterOrUpdater<ToasterState> =
    useSetRecoilState(toasterStore);
  const setRecoilUserForm: SetterOrUpdater<UserFormModel> =
    useSetRecoilState(userFormStore);
  const roles: RoleModel[] = useRecoilValue(rolesStore);
  const userFormValues: UserFormModel = useRecoilValue(userFormStore);
  const setUserFormValues: SetterOrUpdater<UserFormModel> =
    useSetRecoilState(userFormStore);
  const [_isUserFromAutocomplete, setIsUserFromAutocomplete] =
    useState<boolean>(false);
  console.log('_isUserFromAutocomplete', _isUserFromAutocomplete);
  const [options, setOptions] = useState<UserModel[]>([]);
  const [inputValue, setInputValue] = useState('');

  const userIsItself = false;
  // if (dashboardUser?.email === user?.email) {
  //    userIsItself = true;
  // }

  useEffect(() => {
    // Check if the input value is empty before making a request
    if (inputValue && inputValue.length > 2) {
      if (!application) return;
      autocomplete(getAccessTokenSilently, application?.clientId, inputValue)
        .then((users: UserModel[]) => {
          setOptions(users);
        })
        .catch(error => {
          console.error('Error fetching autocomplete data:', error);
        });
    } else {
      setOptions([]);
    }
  }, [inputValue]);
  /*HOOKS*/

  /*HANDLERS*/
  const handleCreate = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      await createUser(
        getAccessTokenSilently,
        application.clientId,
        userFormValues.email,
        userFormValues.rolesIds,
      );
      await loadUsers(getAccessTokenSilently, application, setRecoilUsers);
      setRecoilUser(null);
      resetUserFormStore(setRecoilUserForm);
      goto(`/application/${application.id}/users`);
      sendToaster('User created', 'success', setRecoilToaster);
    } catch (e: unknown) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      if (!user) {
        sendToaster('User not found', 'error', setRecoilToaster);
        return;
      }
      await updateRoles(
        getAccessTokenSilently,
        application.clientId,
        user.id,
        userFormValues.rolesIds,
      );
      await loadUsers(getAccessTokenSilently, application, setRecoilUsers);
      await loadUser(
        getAccessTokenSilently,
        application,
        setRecoilUser,
        user.id,
      );
      resetUserFormStore(setRecoilUserForm);
      goto(`/application/${application.id}/users`);
      sendToaster('User updated', 'success', setRecoilToaster);
    } catch (e: unknown) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleDelete = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      if (!user) {
        sendToaster('User not found', 'error', setRecoilToaster);
        return;
      }
      await removeUser(getAccessTokenSilently, application.clientId, user.id);
      setRecoilUser(null);
      resetUserFormStore(setRecoilUserForm);
      await loadUsers(getAccessTokenSilently, application, setRecoilUsers);
      goto(`/application/${application.id}/users`);
      sendToaster('User deleted', 'success', setRecoilToaster);
    } catch (e: unknown) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setUserFormStoreRolesIds(
      userFormValues,
      setRecoilUserForm,
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSelectionChange = (event, value: UserModel) => {
    if (!value) return;
    event.preventDefault();
    const error = '';
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.email,
      )
    ) {
      setUserFormStoreEmailError(
        userFormValues,
        setRecoilUserForm,
        'Invalid email',
      );
    }
    setUserFormValues(
      plainToInstance(UserFormModel, {
        ...userFormValues,
        email: value.email,
        emailError: error,
      }),
    );
    setOptions([]);
    setInputValue('');
    setIsUserFromAutocomplete(true);
  };
  /*HANDLERS*/

  if (mode === 'delete' && !user) return <></>;
  if (mode === 'update' && !user) return <></>;

  let button = <></>;
  if (mode === 'delete' && user) {
    button = (
      <Button style={buttonStyle} variant="contained" onClick={handleDelete}>
        Delete
      </Button>
    );
  } else if (mode === 'update' && user) {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleUpdate}>
        Update
      </Button>
    );
  } else if (mode === 'create') {
    button = (
      <Button
        disabled={userFormValues.emailError.length !== 0}
        style={buttonStyle}
        variant="contained"
        onClick={handleCreate}
      >
        Create
      </Button>
    );
  }

  if (userIsItself) {
    button = <></>;
  }

  return (
    <div className="UserForm">
      <form method={'POST'}>
        {mode !== 'create' || userIsItself ? (
          <></>
        ) : (
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                disablePortal
                id="user-autocomplete"
                options={options}
                onChange={handleSelectionChange}
                getOptionLabel={option => option.email}
                sx={{ width: 300 }}
                onInputChange={(
                  event: SyntheticEvent,
                  newInputValue: string,
                ) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField {...params} label="Search user" />
                )}
              />
            </FormControl>
          </div>
        )}
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <TextField
              error={userFormValues.emailError.length > 0}
              helperText={userFormValues.emailError}
              disabled={mode === 'delete' || mode === 'update' || userIsItself}
              value={userFormValues.email}
              onChange={event => {
                event.preventDefault();
                const error = '';
                if (
                  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    event.target.value,
                  )
                ) {
                  setUserFormStoreEmailError(
                    userFormValues,
                    setRecoilUserForm,
                    'Invalid email',
                  );
                }
                setUserFormValues(
                  plainToInstance(UserFormModel, {
                    ...userFormValues,
                    email: event.target.value,
                    emailError: error,
                  }),
                );
                setIsUserFromAutocomplete(false);
              }}
              id="standard-basic"
              label="Email"
              variant="standard"
            />
          </FormControl>
        </div>
        <div>
          {userIsItself ? (
            <></>
          ) : (
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Roles</InputLabel>
              <Select
                disabled={props.mode === 'delete'}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={userFormValues.rolesIds}
                onChange={handleChange}
                input={<OutlinedInput label="Roles" />}
                MenuProps={MenuProps}
              >
                {roles.map((role: RoleModel) => (
                  <MenuItem
                    key={role.id}
                    value={role.id}
                    style={getStyles(role, roles, theme)}
                  >
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            {button}
            <br />
            <Button
              style={buttonStyle}
              onClick={() => {
                router.navigate(`/application/${application?.id}/users`);
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </FormControl>
        </div>
      </form>
    </div>
  );
};
