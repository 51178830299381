import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { ApplicationModel, getApplication, getApplications } from '../api';
import { plainToInstance } from 'class-transformer';

export const applicationsStore: RecoilState<ApplicationModel[]> = atom<
  ApplicationModel[]
>({
  key: 'applicationsStore',
  default: [],
});

export const loadApplications = async (
  getAccessTokenSilently: CallableFunction,
  setRecoil: SetterOrUpdater<ApplicationModel[]>,
): Promise<ApplicationModel[]> => {
  const applications: ApplicationModel[] = await getApplications(
    getAccessTokenSilently,
  );
  setRecoil(plainToInstance(ApplicationModel, applications));
  return applications;
};

export const loadApplication = async (
  getAccessTokenSilently: CallableFunction,
  setRecoil: SetterOrUpdater<ApplicationModel | null>,
  applicationId: number | string | undefined,
): Promise<ApplicationModel> => {
  if (applicationId === undefined) {
    throw new Error('applicationId is undefined');
  }
  if (typeof applicationId === 'string') {
    applicationId = parseInt(applicationId);
  }
  const application: ApplicationModel = await getApplication(
    getAccessTokenSilently,
    applicationId,
  );
  setRecoil(plainToInstance(ApplicationModel, application));
  return application;
};
