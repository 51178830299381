import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export class PermissionFormModel {
  name: string;
  description: string;
  nameError = '';
  descriptionError = '';
  rolesIdsError = '';
  rolesIds: number[] = [];
}

export const defaultPermissionFormModel: PermissionFormModel = {
  name: '',
  description: '',
  nameError: '',
  descriptionError: '',
  rolesIdsError: '',
  rolesIds: [],
};

export const permissionFormStore: RecoilState<PermissionFormModel> =
  atom<PermissionFormModel>({
    key: 'permissionFormStore',
    default: defaultPermissionFormModel,
  });

export const setPermissionFormStoreName = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  name: string,
) => {
  setRecoil({ ...currentState, name });
};

export const setPermissionFormStoreDescription = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  description: string,
) => {
  setRecoil({ ...currentState, description });
};

export const setPermissionFormStoreRoleId = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  rolesIds: number[],
) => {
  setRecoil({ ...currentState, rolesIds });
};

export const setPermissionFormStoreNameError = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  nameError: string,
) => {
  setRecoil({ ...currentState, nameError });
};

export const setPermissionFormStoreDescriptionError = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  descriptionError: string,
) => {
  setRecoil({ ...currentState, descriptionError });
};

export const setPermissionFormStoreRoleIdError = (
  currentState: PermissionFormModel,
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  rolesIdsError: string,
) => {
  setRecoil({ ...currentState, rolesIdsError });
};

export const resetPermissionFormStore = (
  setRecoil: SetterOrUpdater<PermissionFormModel>,
) => {
  setRecoil(defaultPermissionFormModel);
};

export const setPermissionFormStoreValues = (
  setRecoil: SetterOrUpdater<PermissionFormModel>,
  values: PermissionFormModel,
) => {
  setRecoil(values);
};
