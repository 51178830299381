import './ProxyPage.css';
import { ListingPage } from '../ListingPage';
import { ViewPage } from '../ViewPage';
import { FormPage } from '../FormPage';
import { useLoadEntitiesFromUrl } from '../../hooks/load-entities-from-url';
import React from 'react';
import { Breadcrumb } from '../../Breadcrumb';
import { EntityTypes } from '../../types';

export interface ProxyPageProps {
  title: string;
  entityType: EntityTypes;
  pageType: 'listing' | 'view' | 'form';
  formType?: 'create' | 'update' | 'delete';
}

export const ProxyPage = (props: ProxyPageProps) => {
  useLoadEntitiesFromUrl(props.pageType, props.entityType, props.formType);
  const entityType: EntityTypes = props.entityType;
  const pageType: 'listing' | 'view' | 'form' = props.pageType;
  const formType: 'create' | 'update' | 'delete' | undefined = props.formType;

  let output = <></>;

  if (pageType === 'form') {
    output = <FormPage entityType={entityType} formType={formType} />;
  } else if (pageType === 'view') {
    output = <ViewPage entityType={entityType} />;
  } else if (pageType === 'listing') {
    output = <ListingPage entityType={entityType} />;
  } else {
    output = <div>Unknown page type</div>;
  }

  return (
    <div className={'proxy-page'}>
      <Breadcrumb />
      {output}
    </div>
  );
};
