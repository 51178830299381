import { axiosCall, clientIdHeaderName } from '../../constants';
import { RoleModel } from '../models';

export const removeRole = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  roleId: number,
): Promise<RoleModel[]> => {
  return axiosCall<RoleModel[]>(
    getAccessTokenSilently,
    'roles/' + roleId,
    'delete',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
