import './SideBar.css';
import { Link } from 'react-router-dom';
import React from 'react';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { sidebarMenuItemStyle, sidebarMenuItemStyleActive } from '../../styles';

export interface SideBarLink {
  active: boolean;
  label: string;
  link: string;
}

export interface SideBarProps {
  links: SideBarLink[];
}

export const SideBar = (props: SideBarProps) => {
  return (
    <List style={{ padding: 0 }} className={'side-bar-link'}>
      {props.links.map((link: SideBarLink, idx: number) => {
        return (
          <ListItem key={link.label + idx} disablePadding>
            <Link
              style={
                link.active ? sidebarMenuItemStyleActive : sidebarMenuItemStyle
              }
              to={link.link}
            >
              <ListItemButton>
                <ListItemText primary={link.label} />
              </ListItemButton>
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};
