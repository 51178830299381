import { axiosCall, clientIdHeaderName } from '../../constants';
import { UserModel } from '../models';

export const getOneUser = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  userId: number,
): Promise<UserModel> => {
  return axiosCall<UserModel>(
    getAccessTokenSilently,
    'users/' + userId,
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
