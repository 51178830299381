import { axiosCall, clientIdHeaderName } from '../../constants';
import { PermissionModel } from '../models';
import { plainToInstance } from 'class-transformer';
import { CreatePermissionDto } from './dto';

export const createPermission = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  name: string,
  description: string,
  rolesIds: number[],
): Promise<PermissionModel> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<PermissionModel>(
      getAccessTokenSilently,
      'permissions',
      'post',
      plainToInstance(CreatePermissionDto, {
        name,
        description,
        rolesIds,
      }),
      { [clientIdHeaderName]: clientId },
    )
      .then((response: PermissionModel) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
