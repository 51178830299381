import React from 'react';
import './FormPage.css';
import { PageWithSideBar } from '../PageWithSideBar';
import {
  ApplicationModelForm,
  PermissionForm,
  RolesForm,
  UserForm,
} from '../../forms';
import { useRecoilValue } from 'recoil';
import { applicationStore } from '../../stores';
import { ApplicationModel } from '../../api';
import { EntityTypes } from '../../types';

export interface FormPageProps {
  entityType: EntityTypes;
  formType?: 'create' | 'update' | 'delete';
}

export const FormPage = (props: FormPageProps) => {
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const entityType: EntityTypes = props.entityType;
  const formType: 'create' | 'update' | 'delete' | undefined = props.formType;
  if (!formType) throw new Error('formType is required');
  if (entityType === 'application') {
    if (formType === 'create') {
      return <ApplicationModelForm mode={formType} />;
    } else {
      return (
        <PageWithSideBar
          links={[
            {
              active: false,
              label: 'Users',
              link: `/application/${application?.id}/users`,
            },
            {
              active: false,
              label: 'Roles',
              link: `/application/${application?.id}/roles`,
            },
            {
              active: false,
              label: 'Permissions',
              link: `/application/${application?.id}/permissions`,
            },
          ]}
        >
          <ApplicationModelForm mode={formType} />
        </PageWithSideBar>
      );
    }
  } else if (entityType === 'user') {
    return (
      <PageWithSideBar
        links={[
          {
            active: true,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: false,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: false,
            label: 'Permission',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <UserForm mode={formType} />
      </PageWithSideBar>
    );
  } else if (entityType === 'role') {
    return (
      <PageWithSideBar
        links={[
          {
            active: false,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: true,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: false,
            label: 'Permission',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <RolesForm mode={formType} />
      </PageWithSideBar>
    );
  } else if (entityType === 'permission') {
    return (
      <PageWithSideBar
        links={[
          {
            active: false,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: false,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: true,
            label: 'Permission',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <PermissionForm mode={formType} />
      </PageWithSideBar>
    );
  } else if (entityType === 'applicationPermissions') {
    return (
      <PageWithSideBar
        links={[
          {
            active: false,
            label: 'Users',
            link: `/application/${application?.id}/users`,
          },
          {
            active: false,
            label: 'Roles',
            link: `/application/${application?.id}/roles`,
          },
          {
            active: true,
            label: 'Permission',
            link: `/application/${application?.id}/permissions`,
          },
        ]}
      >
        <PermissionForm applicationPermissionsMode={true} mode={formType} />
      </PageWithSideBar>
    );
  } else {
    return <div>Unknown entity type</div>;
  }
};
