import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export class UserFormModel {
  email: string;
  rolesIds: number[] = [];
  emailError = '';
}

export const defaultUserFormModel: UserFormModel = {
  email: '',
  rolesIds: [],
  emailError: '',
};

export const userFormStore: RecoilState<UserFormModel> = atom<UserFormModel>({
  key: 'userFormStore',
  default: defaultUserFormModel,
});

export const setUserFormStoreEmail = (
  currentState: UserFormModel,
  setRecoil: SetterOrUpdater<UserFormModel>,
  email: string,
) => {
  setRecoil({ ...currentState, email });
};

export const setUserFormStoreRolesIds = (
  currentState: UserFormModel,
  setRecoil: SetterOrUpdater<UserFormModel>,
  rolesIds: number[],
) => {
  setRecoil({ ...currentState, rolesIds });
};

export const setUserFormStoreEmailError = (
  currentState: UserFormModel,
  setRecoil: SetterOrUpdater<UserFormModel>,
  emailError: string,
) => {
  setRecoil({ ...currentState, emailError });
};

export const resetUserFormStore = (
  setRecoil: SetterOrUpdater<UserFormModel>,
) => {
  setRecoil(defaultUserFormModel);
};

export const setUserFormStoreValues = (
  setRecoil: SetterOrUpdater<UserFormModel>,
  values: UserFormModel,
) => {
  setRecoil(values);
};
