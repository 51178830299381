import { axiosCall, clientIdHeaderName } from '../../constants';
import { plainToInstance } from 'class-transformer';
import { CreateRoleDto } from './dto';
import { RoleModel } from '../models';

export const createRole = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  name: string,
  description: string,
): Promise<RoleModel> => {
  return axiosCall<RoleModel>(
    getAccessTokenSilently,
    'roles',
    'post',
    plainToInstance(CreateRoleDto, {
      name,
      description,
    }),
    { [clientIdHeaderName]: clientId },
  );
};
