let domain: string | undefined = 'int-vallourec.eu.auth0.com';
let clientId: string | undefined = 'seNXrBSe8ESDtG5peOo6xwsV9nl07Lyk';
let audience: string | undefined = 'https://api.auth.app.int.vallourec.com';
let scope: string | undefined = '';
let env: string | undefined = 'INT';
const envs: unknown = process.env;

if (process.env.REACT_APP_ENV !== undefined) {
  env = process.env.REACT_APP_ENV?.toUpperCase();
  if (process.env['REACT_APP_AUTH0_DOMAIN_' + env] !== undefined) {
    domain = process.env['REACT_APP_AUTH0_DOMAIN_' + env];
  }
  if (process.env['REACT_APP_AUTH0_CLIENT_ID_' + env] !== undefined) {
    clientId = process.env['REACT_APP_AUTH0_CLIENT_ID_' + env];
  }
  if (process.env['REACT_APP_AUTH0_AUDIENCE_' + env] !== undefined) {
    audience = process.env['REACT_APP_AUTH0_AUDIENCE_' + env];
  }
}

scope = 'openid email profile';

export { domain, clientId, audience, scope, env, envs };
