import { axiosCall, clientIdHeaderName } from '../../constants';
import { UsersPaginatedResultDto } from './dto';

export const getAllUsersPaginated = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  page = 1,
): Promise<UsersPaginatedResultDto> => {
  return axiosCall<UsersPaginatedResultDto>(
    getAccessTokenSilently,
    'users/list/' + page,
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
