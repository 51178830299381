import { axiosCall } from '../../constants';
import { ApplicationModel } from '../models';

export const deleteApplication = async (
  getAccessTokenSilently: CallableFunction,
  applicationId: number | null,
): Promise<ApplicationModel[]> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<ApplicationModel[]>(
      getAccessTokenSilently,
      'applications/' + applicationId,
      'delete',
    )
      .then((applications: ApplicationModel[]) => {
        resolve(applications);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
