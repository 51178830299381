import './RolesForm.css';

import React from 'react';
import {
  applicationStore,
  loadOneRole,
  loadRoles,
  resetRoleFormStore,
  RoleFormModel,
  roleFormStore,
  rolesStore,
  selectedRoleStore,
  sendToaster,
  ToasterState,
  toasterStore,
} from '../../stores';
import {
  ApplicationModel,
  createRole,
  removeRole,
  RoleModel,
  updateRole,
} from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, FormControl, TextField } from '@mui/material';
import { buttonStyle } from '../../styles';
import { router } from '../../router';
import { useAuth0 } from '@auth0/auth0-react';
import { goto, toastErrorFromApiCatch } from '../../helpers';
import { plainToInstance } from 'class-transformer';

export interface RoleModelFormProps {
  mode: 'update' | 'delete' | 'create';
  role?: RoleModel;
}

export const RolesForm = (props: RoleModelFormProps) => {
  /*VARIABLES*/
  const mode: 'update' | 'delete' | 'create' = props.mode;
  /*VARIABLES*/

  /*HOOKS*/
  const { getAccessTokenSilently } = useAuth0();
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const setRecoilRole: SetterOrUpdater<RoleModel | null> =
    useSetRecoilState(selectedRoleStore);
  const setRecoilRoles: SetterOrUpdater<RoleModel[]> =
    useSetRecoilState(rolesStore);
  const setRecoilToaster: SetterOrUpdater<ToasterState> =
    useSetRecoilState(toasterStore);
  const setRecoilRoleForm: SetterOrUpdater<RoleFormModel> =
    useSetRecoilState(roleFormStore);
  const setSelectedRole: CallableFunction =
    useSetRecoilState(selectedRoleStore);
  const role: RoleModel | null = useRecoilValue(selectedRoleStore);
  const roleFormValues: RoleFormModel = useRecoilValue(roleFormStore);
  const setRecoildRoleFormValues: SetterOrUpdater<RoleFormModel> =
    useSetRecoilState(roleFormStore);
  /*HOOKS*/

  /*HANDLERS*/
  const handleCreate = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      if (roleFormValues.name.match(/^[a-zA-Z0-9-]*$/g) === null) {
        sendToaster(
          'Role name name can only contain alphanumeric characters and -',
          'error',
          setRecoilToaster,
        );
        return;
      }
      await createRole(
        getAccessTokenSilently,
        application.clientId,
        roleFormValues.name,
        roleFormValues.description,
      );
      await loadRoles(getAccessTokenSilently, application, setRecoilRoles);
      setSelectedRole(null);
      resetRoleFormStore(setRecoilRoleForm);
      goto(`/application/${application.id}/roles`);
      sendToaster('Role created', 'success', setRecoilToaster);
    } catch (e: any) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    try {
      if (!role) {
        sendToaster('Role not found', 'error', setRecoilToaster);
        return;
      }
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }

      if (roleFormValues.name.match(/^[a-zA-Z0-9-]*$/g) === null) {
        sendToaster(
          'Role name name can only contain alphanumeric characters and -',
          'error',
          setRecoilToaster,
        );
        return;
      }

      await updateRole(
        getAccessTokenSilently,
        application.clientId,
        roleFormValues.name,
        roleFormValues.description,
        role.id,
      );
      await loadRoles(getAccessTokenSilently, application, setRecoilRoles);
      await loadOneRole(
        getAccessTokenSilently,
        application,
        setRecoilRole,
        role.id,
      );
      resetRoleFormStore(setRecoilRoleForm);
      goto(`/application/${application.id}/roles`);
      sendToaster('Role updated', 'success', setRecoilToaster);
    } catch (e: any) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleDelete = async e => {
    e.preventDefault();
    try {
      if (!role) {
        sendToaster('Role not found', 'error', setRecoilToaster);
        return;
      }
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      await removeRole(getAccessTokenSilently, application.clientId, role.id);
      setSelectedRole(null);
      await loadRoles(getAccessTokenSilently, application, setRecoilRoles);
      goto(`/application/${application.id}/roles`);
      resetRoleFormStore(setRecoilRoleForm);
      sendToaster('Role deleted', 'success', setRecoilToaster);
    } catch (e: any) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };
  /*HANDLERS*/

  /*CONDITIONS*/
  if (!application) return <></>;
  if (mode === 'delete' && !role) return <></>;
  if (mode === 'update' && !role) return <></>;
  /*CONDITIONS*/

  let button = <></>;
  if (mode === 'delete' && role) {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleDelete}>
        Delete
      </Button>
    );
  } else if (mode === 'update' && role) {
    button = (
      <Button
        variant="contained"
        style={buttonStyle}
        disabled={
          roleFormValues.nameError.length !== 0 ||
          roleFormValues.descriptionError.length !== 0
        }
        onClick={handleUpdate}
      >
        Update
      </Button>
    );
  } else {
    button = (
      <Button
        variant="contained"
        style={buttonStyle}
        disabled={
          roleFormValues.nameError.length !== 0 ||
          roleFormValues.descriptionError.length !== 0
        }
        onClick={handleCreate}
      >
        Create
      </Button>
    );
  }

  return (
    <form method={'POST'}>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <TextField
            error={roleFormValues.nameError.length > 0}
            helperText={roleFormValues.nameError}
            disabled={mode === 'delete'}
            value={roleFormValues.name}
            onChange={event => {
              event.preventDefault();
              let error = '';
              if (event.target.value.length < 3) {
                error = 'Name must be at least 3 characters long';
              }
              setRecoildRoleFormValues(
                plainToInstance(RoleFormModel, {
                  ...roleFormValues,
                  name: event.target.value,
                  nameError: error,
                }),
              );
            }}
            id="standard-basic-name"
            label="Name"
            variant="standard"
          />
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <TextField
            error={roleFormValues.descriptionError.length > 0}
            helperText={roleFormValues.descriptionError}
            disabled={mode === 'delete'}
            value={roleFormValues.description}
            onChange={event => {
              event.preventDefault();
              let error = '';
              if (
                event.target.value.trim().length > 0 &&
                event.target.value.trim().length < 6
              ) {
                error = 'Name must be at least 6 characters long';
              }
              setRecoildRoleFormValues(
                plainToInstance(RoleFormModel, {
                  ...roleFormValues,
                  description: event.target.value,
                  descriptionError: error,
                }),
              );
            }}
            id="standard-basic-description"
            label="Description"
            variant="standard"
          />
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          {button}
          <br />
          <Button
            style={buttonStyle}
            onClick={() => {
              router.navigate(`/application/${application.id}/roles`);
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </FormControl>
      </div>
    </form>
  );
};
