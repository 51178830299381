import React from 'react';
import './PageWithSideBar.css';
import { SideBar, SideBarLink } from '../SideBar';

export interface PageWithSideBarProps {
  links: SideBarLink[];
  children: React.ReactNode;
}

export const PageWithSideBar = (props: PageWithSideBarProps) => {
  return (
    <div className="PageWithSideBar">
      <div className="side-bar">
        <SideBar links={props.links} />
      </div>
      <div className="PageWithSideBarContent">{props.children}</div>
    </div>
  );
};
