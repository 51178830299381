import './Loader.css';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { loadingStore } from '../stores';

export const Loader = () => {
  const loading = useRecoilValue(loadingStore);
  if (!loading) return <></>;
  return (
    <div className={'loader'}>
      <CircularProgress />
    </div>
  );
};
