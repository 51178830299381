import { sendToaster, ToasterState } from '../stores';
import { SetterOrUpdater } from 'recoil';

export const toastErrorFromApiCatch = (
  error: { message: string; response: { data: { message: string } } },
  setRecoil: SetterOrUpdater<ToasterState>,
) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return sendToaster(error.response.data.message, 'error', setRecoil);
  } else {
    sendToaster(error.message, 'error', setRecoil);
  }
};
