import { axiosCall, clientIdHeaderName } from '../../constants';
import { PermissionModel } from '../models';

export const getOnePermission = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  permissionId: number,
): Promise<PermissionModel> => {
  return axiosCall<PermissionModel>(
    getAccessTokenSilently,
    'permissions/' + permissionId,
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
