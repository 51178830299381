import React from 'react';
import './UserProfile.css';
import { useAuth0 } from '@auth0/auth0-react';
import { buttonStyleWithBlck } from '../styles';
import { Button } from '@mui/material';

export const UserProfile = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const loginLogoutButton = isAuthenticated ? (
    <Button
      variant="contained"
      style={buttonStyleWithBlck}
      onClick={() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
    >
      LOGOUT
    </Button>
  ) : (
    <Button
      variant="contained"
      style={buttonStyleWithBlck}
      onClick={() => {
        loginWithRedirect();
      }}
    >
      LOGIN
    </Button>
  );

  return <>{loginLogoutButton}</>;
};
