import { axiosCall, clientIdHeaderName } from '../../constants';
import { UserModel } from '../models';
import { plainToInstance } from 'class-transformer';
import { CreateUserDto } from '../roles';

export const createUser = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  email: string,
  rolesIds?: number[],
): Promise<UserModel> => {
  return axiosCall<UserModel>(
    getAccessTokenSilently,
    'users',
    'post',
    plainToInstance(CreateUserDto, {
      email,
      rolesIds,
    }),
    { [clientIdHeaderName]: clientId },
  );
};
