import { axiosCall, clientIdHeaderName } from '../../constants';
import { PermissionModel } from '../models';

export const removePermission = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  permissionId: number,
): Promise<PermissionModel[]> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<PermissionModel[]>(
      getAccessTokenSilently,
      'permissions/' + permissionId,
      'delete',
      undefined,
      { [clientIdHeaderName]: clientId },
    )
      .then((response: PermissionModel[]) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
