import React from 'react';
import './RoleOverview.css';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationModel, PermissionModel, RoleModel } from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  applicationStore,
  loadOnePermission,
  selectedPermissionsStore,
  selectedRoleStore,
} from '../../stores';
import { useEffect } from 'react';

export const RoleOverview = () => {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();

  if (!params.roleId) throw new Error('Role is required');
  const roleId = parseInt(params.roleId);
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const role: RoleModel | null = useRecoilValue(selectedRoleStore);
  const setRecoilPermission: SetterOrUpdater<PermissionModel | null> =
    useSetRecoilState(selectedPermissionsStore);

  useEffect(() => {
    if (!role)
      loadOnePermission(
        getAccessTokenSilently,
        application,
        setRecoilPermission,
        roleId,
      );
  }, [role]);

  return <div>RoleOverview</div>;
};
