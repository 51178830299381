import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import {
  ApplicationModel,
  createPermission,
  getAllPermissions,
  getOnePermission,
  PermissionModel,
  removePermission,
  updatePermission,
} from '../api';
import { plainToInstance } from 'class-transformer';
import { noApplicationSelected } from '../constants';

export const permissionsStore: RecoilState<PermissionModel[]> = atom<
  PermissionModel[]
>({
  key: 'permissionsStore',
  default: [],
});

export const selectedPermissionsStore: RecoilState<PermissionModel | null> =
  atom<PermissionModel | null>({
    key: 'selectedPermissionsStore',
    default: null,
  });

export const loadPermissions = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<PermissionModel[]>,
): Promise<PermissionModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  let permissions: PermissionModel[] = await getAllPermissions(
    getAccessTokenSilently,
    application.clientId,
  );
  permissions = permissions.map((permission: PermissionModel) => {
    permission.roles = permission.__roles__;
    return permission;
  });
  setRecoil(permissions);
  return permissions;
};

export const loadOnePermission = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<PermissionModel | null>,
  permissionId: number | string | undefined,
): Promise<PermissionModel> => {
  if (permissionId === undefined) {
    throw new Error('permissionId is undefined');
  }
  if (typeof permissionId === 'string') {
    permissionId = parseInt(permissionId);
  }
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const permission: PermissionModel = await getOnePermission(
    getAccessTokenSilently,
    application.clientId,
    permissionId,
  );
  permission.roles = permission.__roles__;
  setRecoil(plainToInstance(PermissionModel, permission));
  return permission;
};

export const createPermissionHandler = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<PermissionModel | null>,
  setRecoilPermissions: SetterOrUpdater<PermissionModel[]>,
  rolesIds: number[],
  name: string,
  description: string,
): Promise<PermissionModel> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const permission: PermissionModel = await createPermission(
    getAccessTokenSilently,
    application.clientId,
    name,
    description,
    rolesIds,
  );
  setRecoil(plainToInstance(PermissionModel, permission));
  await loadPermissions(
    getAccessTokenSilently,
    application,
    setRecoilPermissions,
  );
  return permission;
};

export const deletePermission = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<PermissionModel[]>,
  permissionId: number,
): Promise<PermissionModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const permissions: PermissionModel[] = await removePermission(
    getAccessTokenSilently,
    application.clientId,
    permissionId,
  );
  setRecoil(plainToInstance(PermissionModel, permissions));
  return permissions;
};

export const updatePermissionHandler = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<PermissionModel | null>,
  setRecoilPermissions: SetterOrUpdater<PermissionModel[]>,
  permissionId: number,
  name: string,
  description: string,
  rolesIds: number[],
): Promise<PermissionModel> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const permission: PermissionModel = await updatePermission(
    getAccessTokenSilently,
    application.clientId,
    permissionId,
    name,
    description,
    rolesIds,
  );
  setRecoil(plainToInstance(PermissionModel, permission));
  await loadPermissions(
    getAccessTokenSilently,
    application,
    setRecoilPermissions,
  );
  return permission;
};
