import { PermissionModel } from './permission.model';
import { UserModel } from './user.model';
import { ApplicationModel } from './application.model';
import { Type } from 'class-transformer';

export class RoleModel {
  id: number;
  name: string;
  description: string;
  @Type(() => PermissionModel)
  permissions: PermissionModel[];

  @Type(() => UserModel)
  users: UserModel[];

  @Type(() => ApplicationModel)
  application: ApplicationModel;
}
