import { UserRolesAndPermissionDto } from '../../users';
import { Type } from 'class-transformer';

export class UserWhoiamDto {
  email: string;
  auth0Id: string[];

  @Type(() => UserRolesAndPermissionDto)
  applications: UserRolesAndPermissionDto[];
  isDashboardAdmin: boolean;

  get adminOf(): string[] {
    const adminOf: string[] = [];
    this.applications.forEach((application: UserRolesAndPermissionDto) => {
      if (application.roles.includes('admin')) {
        adminOf.push(application.clientName);
      }
    });
    return adminOf;
  }

  get adminOfIds(): number[] {
    const adminOf: number[] = [];
    this.applications.forEach((application: UserRolesAndPermissionDto) => {
      if (application.roles.includes('admin')) {
        adminOf.push(application.id);
      }
    });
    return adminOf;
  }
}
