export const buttonStyle = {
  borderColor: 'black',
  color: 'black',
  backgroundColor: 'white',
};

export const buttonStyleWithBlck = {
  color: 'black',
  backgroundColor: 'white',
};

export const buttonStyleTableAction = {
  color: 'black',
  backgroundColor: 'white',
  marginRight: '10px',
  borderColor: 'black',
  height: '30px',
  marginBottom: '10px',
};
