import React from 'react';
import './ViewPage.css';
import {
  ApplicationOverview,
  PermissionOverview,
  RoleOverview,
  UserOverview,
} from '../../overviews';
import { EntityTypes } from '../../types';

export interface ViewPageProps {
  entityType: EntityTypes;
}

export const ViewPage = (props: ViewPageProps) => {
  if (props.entityType === 'application') {
    return <ApplicationOverview />;
  } else if (props.entityType === 'user') {
    return <UserOverview />;
  } else if (props.entityType === 'role') {
    return <RoleOverview />;
  } else if (props.entityType === 'permission') {
    return <PermissionOverview />;
  } else {
    return <div>ViewPage</div>;
  }
};
