import './DashboardHeader.css';
import { UserProfile } from '../UserProfile';
import React from 'react';
import { router } from '../router';
import { useRecoilValue } from 'recoil';
import { userStore } from '../stores';
import { Chip } from '@mui/material';
import { UserWhoiamDto } from '../api';
import { env } from '../constants';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import logoutIcon from './logout.png';
import appsIcon from './apps.png';
import gotoIcon from './goto.png';
import logoGsi from './logo.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth0 } from '@auth0/auth0-react';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const DashboardHeader = () => {
  const { isAuthenticated, logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const gotoTenant = (env: string) => {
    if (env === 'prod' || env === 'master') {
      window.location.href = `https://dashboard.app.vallourec.com`;
      return;
    }
    window.location.href = `https://dashboard.app.${env}.vallourec.com`;
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    setAnchorEl(null);
  };

  const dashboardUser: UserWhoiamDto | null = useRecoilValue(userStore);

  if (env === undefined || env === null || env === '') {
    return <></>;
  }

  return (
    <div className={'top-bar'}>
      <div className={'app-name'}>
        <img
          src={logoGsi}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            router.navigate('/');
          }}
          className={'vallourec-logo'}
        />
      </div>
      <div className={'env-show'}>
        <Chip sx={{ backgroundColor: 'white', color: 'black' }} label={env} />
      </div>
      {isAuthenticated ? (
        <></>
      ) : (
        <div className={'login-logout-button'}>
          <UserProfile />
        </div>
      )}
      {isAuthenticated ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '140px',
            height: '100%',
          }}
        >
          <Button
            style={{ backgroundColor: 'white', color: 'black' }}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {dashboardUser?.email.split('@')[0].split('.')[0].substring(0, 1) +
              '' +
              dashboardUser?.email.split('@')[0].split('.')[0].substring(1, 2)}
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disableRipple>{dashboardUser?.email}</MenuItem>
            {env.toLowerCase() === 'int' ? (
              <></>
            ) : (
              <MenuItem
                onClick={() => {
                  gotoTenant('int');
                }}
                disableRipple
              >
                <img
                  src={gotoIcon}
                  style={{ height: 16, width: 16, marginRight: 10 }}
                />
                Integration
              </MenuItem>
            )}
            {env.toLowerCase() === 'qual' ? (
              <></>
            ) : (
              <MenuItem
                onClick={() => {
                  gotoTenant('qual');
                }}
                disableRipple
              >
                <img
                  src={gotoIcon}
                  style={{ height: 16, width: 16, marginRight: 10 }}
                />
                Qualification
              </MenuItem>
            )}
            {env.toLowerCase() === 'prod' ? (
              <></>
            ) : (
              <MenuItem
                onClick={() => {
                  gotoTenant('prod');
                }}
                disableRipple
              >
                <img
                  src={gotoIcon}
                  style={{ height: 16, width: 16, marginRight: 10 }}
                />
                Production
              </MenuItem>
            )}
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              style={{ display: 'block', cursor: 'normal' }}
              disableRipple
            >
              <div>
                <img
                  src={appsIcon}
                  style={{ height: 16, width: 16, marginRight: 10 }}
                />{' '}
                Applications:{' '}
              </div>
              <ul style={{ listStyleType: 'disc' }}>
                {dashboardUser?.adminOf.map((app: string, key: number) => {
                  return (
                    <li
                      style={{ fontSize: 14, color: 'black' }}
                      key={`app-admin-${key}`}
                      color="text.secondary"
                    >
                      {app}
                    </li>
                  );
                })}
              </ul>
            </MenuItem>
            <MenuItem onClick={handleLogout} disableRipple>
              <img
                src={logoutIcon}
                style={{ height: 16, width: 16, marginRight: 10 }}
              />
              Logout
            </MenuItem>
          </StyledMenu>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
