import { axiosCall, clientIdHeaderName } from '../../constants';
import { RoleModel } from '../models';

export const getOneRole = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  roleId: number,
): Promise<RoleModel> => {
  return axiosCall<RoleModel>(
    getAccessTokenSilently,
    'roles/' + roleId,
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
