import { axiosCall, clientIdHeaderName } from '../../constants';
import { PermissionModel } from '../models';
import { plainToInstance } from 'class-transformer';
import { CreatePermissionDto } from './dto';

export const updatePermission = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  permissionId: number,
  name: string,
  description: string,
  rolesIds: number[],
): Promise<PermissionModel> => {
  return axiosCall<PermissionModel>(
    getAccessTokenSilently,
    'permissions/' + permissionId,
    'put',
    plainToInstance(CreatePermissionDto, {
      name,
      description,
      rolesIds,
    }),
    { [clientIdHeaderName]: clientId },
  );
};
