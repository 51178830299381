import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  ApplicationFormModel,
  applicationFormStore,
  applicationsStore,
  applicationStore,
  defaultApplicationFormModel,
  defaultPermissionFormModel,
  defaultRoleFormModel,
  defaultUserFormModel,
  loadApplication,
  loadApplications,
  loadingStore,
  loadOnePermission,
  loadOneRole,
  loadPermissions,
  loadRoles,
  loadUser,
  loadUsersPaginated,
  paginationStore,
  PaginationStoreModel,
  PermissionFormModel,
  permissionFormStore,
  permissionsStore,
  RoleFormModel,
  roleFormStore,
  rolesStore,
  searchStore,
  searchUsersPaginated,
  selectedPermissionsStore,
  selectedRoleStore,
  selectedUserStore,
  setApplicationFormStoreValues,
  setIsLoading,
  setIsNotLoading,
  setPermissionFormStoreValues,
  setRoleFormStoreValues,
  setUserFormStoreValues,
  UserFormModel,
  userFormStore,
  usersStore,
} from '../stores';
import {
  ApplicationModel,
  PermissionModel,
  RoleModel,
  UserModel,
} from '../api';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router';
import { plainToInstance } from 'class-transformer';
import { EntityTypes } from '../types';

export const useLoadEntitiesFromUrl = (
  pageType: 'listing' | 'view' | 'form',
  entityType: EntityTypes,
  formType?: 'create' | 'update' | 'delete',
) => {
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();
  const location = useLocation();

  const setRecoilApplication: SetterOrUpdater<ApplicationModel | null> =
    useSetRecoilState(applicationStore);
  const setRecoilUser: SetterOrUpdater<UserModel | null> =
    useSetRecoilState(selectedUserStore);
  const setRecoilRole: SetterOrUpdater<RoleModel | null> =
    useSetRecoilState(selectedRoleStore);
  const setRecoilPermission: SetterOrUpdater<PermissionModel | null> =
    useSetRecoilState(selectedPermissionsStore);

  const setRecoilApplications: SetterOrUpdater<ApplicationModel[]> =
    useSetRecoilState(applicationsStore);
  const setRecoilUsers: SetterOrUpdater<UserModel[]> =
    useSetRecoilState(usersStore);
  const setRecoilRoles: SetterOrUpdater<RoleModel[]> =
    useSetRecoilState(rolesStore);

  const setRecoilIsLoading: SetterOrUpdater<boolean> =
    useSetRecoilState(loadingStore);

  const setRecoilApplicationForm: SetterOrUpdater<ApplicationFormModel> =
    useSetRecoilState(applicationFormStore);
  const setRecoilUserForm: SetterOrUpdater<UserFormModel> =
    useSetRecoilState(userFormStore);
  const setRecoilRoleForm: SetterOrUpdater<RoleFormModel> =
    useSetRecoilState(roleFormStore);
  const setRecoilPermissionForm: SetterOrUpdater<PermissionFormModel> =
    useSetRecoilState(permissionFormStore);

  const setRecoilPagination: SetterOrUpdater<PaginationStoreModel> =
    useSetRecoilState(paginationStore);

  const setRecoilPermissions: SetterOrUpdater<PermissionModel[]> =
    useSetRecoilState(permissionsStore);

  const setQ: SetterOrUpdater<string> = useSetRecoilState(searchStore);

  const resolveData = async () => {
    await setIsLoading(setRecoilIsLoading);

    if (pageType === 'listing') {
      let app: ApplicationModel | null = null;
      if (entityType !== 'application') {
        app = await loadApplication(
          getAccessTokenSilently,
          setRecoilApplication,
          params.applicationId,
        );
      }
      if (entityType === 'application') {
        await loadApplications(getAccessTokenSilently, setRecoilApplications);
      } else if (entityType === 'user') {
        if (
          params.q !== undefined &&
          params.q !== null &&
          params.q !== '' &&
          params.q.length > 1
        ) {
          setQ(params.q);
          if (
            params.page !== undefined &&
            params.page !== null &&
            parseInt(params.page)
          ) {
            await searchUsersPaginated(
              getAccessTokenSilently,
              app,
              setRecoilUsers,
              setRecoilPagination,
              params.q,
              parseInt(params.page),
            );
          } else {
            await searchUsersPaginated(
              getAccessTokenSilently,
              app,
              setRecoilUsers,
              setRecoilPagination,
              params.q,
              1,
            );
          }
        } else if (
          params.page !== undefined &&
          params.page !== null &&
          parseInt(params.page)
        ) {
          await loadUsersPaginated(
            getAccessTokenSilently,
            app,
            setRecoilUsers,
            setRecoilPagination,
            parseInt(params.page),
          );
        } else {
          await loadUsersPaginated(
            getAccessTokenSilently,
            app,
            setRecoilUsers,
            setRecoilPagination,
          );
        }
      } else if (entityType === 'role') {
        await loadRoles(getAccessTokenSilently, app, setRecoilRoles);
      } else if (entityType === 'permission') {
        await loadRoles(getAccessTokenSilently, app, setRecoilRoles);
        await loadOneRole(
          getAccessTokenSilently,
          app,
          setRecoilRole,
          params.roleId,
        );
      } else if (entityType === 'applicationPermissions') {
        await loadPermissions(
          getAccessTokenSilently,
          app,
          setRecoilPermissions,
        );
      }
    } else if (pageType === 'view') {
      const app: ApplicationModel | null = await loadApplication(
        getAccessTokenSilently,
        setRecoilApplication,
        params.applicationId,
      );
      if (entityType === 'application') {
      } else if (entityType === 'user') {
        await loadUser(
          getAccessTokenSilently,
          app,
          setRecoilUser,
          params.userId,
        );
      } else if (entityType === 'role') {
        await loadOneRole(
          getAccessTokenSilently,
          app,
          setRecoilRole,
          params.roleId,
        );
      } else if (entityType === 'permission') {
        await loadOneRole(
          getAccessTokenSilently,
          app,
          setRecoilRole,
          params.roleId,
        );
        await loadOnePermission(
          getAccessTokenSilently,
          app,
          setRecoilPermission,
          params.permissionId,
        );
      }
    } else if (pageType === 'form') {
      let app: ApplicationModel = plainToInstance(ApplicationModel, {}); // todo remove
      if (formType !== 'create' || entityType !== 'application') {
        app = await loadApplication(
          getAccessTokenSilently,
          setRecoilApplication,
          params.applicationId,
        );
      }
      if (entityType === 'application') {
        if (formType !== 'create') {
          setApplicationFormStoreValues(
            setRecoilApplicationForm,
            plainToInstance(ApplicationFormModel, {
              clientId: app.clientId,
              ressourceId: app.resourceId,
              administratorsEmails: app.administratorsEmails,
            }),
          );
        } else {
          setApplicationFormStoreValues(
            setRecoilApplicationForm,
            defaultApplicationFormModel,
          );
        }
      } else if (entityType === 'user') {
        await loadRoles(getAccessTokenSilently, app, setRecoilRoles);
        if (formType !== 'create') {
          const user: UserModel = await loadUser(
            getAccessTokenSilently,
            app,
            setRecoilUser,
            params.userId,
          );
          setUserFormStoreValues(
            setRecoilUserForm,
            plainToInstance(UserFormModel, {
              email: user.email,
              password: user.password,
              rolesIds: user.roles
                .filter((role: RoleModel) => {
                  return role.application.id === app.id;
                })
                .map(role => role.id),
            }),
          );
        } else {
          setUserFormStoreValues(setRecoilUserForm, defaultUserFormModel);
        }
      } else if (entityType === 'role') {
        if (formType !== 'create') {
          const role: RoleModel = await loadOneRole(
            getAccessTokenSilently,
            app,
            setRecoilRole,
            params.roleId,
          );
          setRoleFormStoreValues(
            setRecoilRoleForm,
            plainToInstance(RoleFormModel, {
              name: role.name,
              description: role.description,
            }),
          );
        } else {
          setRoleFormStoreValues(setRecoilRoleForm, defaultRoleFormModel);
        }
      } else if (entityType === 'permission') {
        await loadRoles(getAccessTokenSilently, app, setRecoilRoles);
        await loadOneRole(
          getAccessTokenSilently,
          app,
          setRecoilRole,
          params.roleId,
        );
        if (formType !== 'create') {
          const permission: PermissionModel = await loadOnePermission(
            getAccessTokenSilently,
            app,
            setRecoilPermission,
            params.permissionId,
          );
          setPermissionFormStoreValues(
            setRecoilPermissionForm,
            plainToInstance(PermissionFormModel, {
              name: permission.name,
              description: permission.description,
              rolesIds: permission.roles.map(role => role.id),
            }),
          );
        } else {
          setPermissionFormStoreValues(
            setRecoilPermissionForm,
            defaultPermissionFormModel,
          );
        }
      } else if (entityType === 'applicationPermissions') {
        await loadRoles(getAccessTokenSilently, app, setRecoilRoles);
        // await loadOnePermission(getAccessTokenSilently, app, setRecoilPermission, params.permissionId);
        if (formType !== 'create') {
          const permission: PermissionModel = await loadOnePermission(
            getAccessTokenSilently,
            app,
            setRecoilPermission,
            params.permissionId,
          );
          setPermissionFormStoreValues(
            setRecoilPermissionForm,
            plainToInstance(PermissionFormModel, {
              name: permission.name,
              description: permission.description,
              rolesIds: permission.__roles__.map(role => role.id),
            }),
          );
        } else {
          setPermissionFormStoreValues(
            setRecoilPermissionForm,
            defaultPermissionFormModel,
          );
        }
      }
    }

    await setIsNotLoading(setRecoilIsLoading);
  };

  useEffect(() => {
    setIsLoading(setRecoilIsLoading);
    resolveData().then(() => {
      setIsNotLoading(setRecoilIsLoading);
    });
  }, [location.pathname]);
};
