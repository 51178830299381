import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { UserWhoiamDto, whoiam } from '../api';
import { plainToInstance } from 'class-transformer';

export const userStore: RecoilState<UserWhoiamDto | null> =
  atom<UserWhoiamDto | null>({
    key: 'userStore',
    default: null,
  });

export const loadWhoiam = async (
  getAccessTokenSilently: CallableFunction,
  setRecoil: SetterOrUpdater<UserWhoiamDto | null>,
): Promise<UserWhoiamDto> => {
  const user: UserWhoiamDto = await whoiam(getAccessTokenSilently);
  setRecoil(plainToInstance(UserWhoiamDto, user));
  return user;
};
