import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import {
  ApplicationModel,
  getAllUsers,
  getAllUsersPaginated,
  getOneUser,
  removeUser,
  searchAllUsersPaginated,
  UserModel,
  UsersPaginatedResultDto,
} from '../api';
import { plainToInstance } from 'class-transformer';
import { PaginationStoreModel } from './pagination.store';
import { noApplicationSelected } from '../constants';

export const usersStore: RecoilState<UserModel[]> = atom<UserModel[]>({
  key: 'usersStore',
  default: [],
});

export const selectedUserStore: RecoilState<UserModel | null> =
  atom<UserModel | null>({
    key: 'selectedUserStore',
    default: null,
  });

export const loadUsers = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<UserModel[]>,
): Promise<UserModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const users: UserModel[] = await getAllUsers(
    getAccessTokenSilently,
    application.clientId,
  );
  setRecoil(plainToInstance(UserModel, users));
  return users;
};

export const loadUsersPaginated = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<UserModel[]>,
  setRecoilPagination: SetterOrUpdater<PaginationStoreModel>,
  page = 1,
): Promise<UserModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const result: UsersPaginatedResultDto = await getAllUsersPaginated(
    getAccessTokenSilently,
    application.clientId,
    page,
  );
  setRecoil(plainToInstance(UserModel, result.users));
  setRecoilPagination(
    plainToInstance(PaginationStoreModel, {
      total: result.total,
      page: page,
    }),
  );
  return result.users;
};

export const searchUsersPaginated = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<UserModel[]>,
  setRecoilPagination: SetterOrUpdater<PaginationStoreModel>,
  q: string,
  page = 1,
): Promise<UserModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const result: UsersPaginatedResultDto = await searchAllUsersPaginated(
    getAccessTokenSilently,
    application.clientId,
    q,
    page,
  );
  setRecoil(plainToInstance(UserModel, result.users));
  setRecoilPagination(
    plainToInstance(PaginationStoreModel, {
      total: result.total,
      page: page,
    }),
  );
  return result.users;
};

export const deleteUser = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<UserModel[]>,
  userId: number,
): Promise<UserModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const users: UserModel[] = await removeUser(
    getAccessTokenSilently,
    application.clientId,
    userId,
  );
  setRecoil(plainToInstance(UserModel, users));
  return users;
};

export const loadUser = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<UserModel | null>,
  userId: number | string | undefined,
): Promise<UserModel> => {
  if (userId === undefined) {
    throw new Error('userId is undefined');
  }
  if (typeof userId === 'string') {
    userId = parseInt(userId);
  }
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const user: UserModel = await getOneUser(
    getAccessTokenSilently,
    application.clientId,
    userId,
  );
  setRecoil(user);
  return user;
};
