import { RoleModel } from './role.model';
import { Type } from 'class-transformer';

export class ApplicationModel {
  id: number;
  clientId: string;
  resourceId: string;

  @Type(() => RoleModel)
  roles: RoleModel[];
  name: string;
  administratorsEmails: string[];
  deleted: boolean;
}
