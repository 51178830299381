import axios, { AxiosInstance } from 'axios';
import { apiUrl } from './apiUrl';
import { HttpHeaders } from '../api';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

export const axiosCall = <T>(
  getAccessTokenSilently: CallableFunction,
  path: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete' | 'option' = 'get',
  payload?: unknown,
  additionalHeaders?: HttpHeaders,
): Promise<T> => {
  console.log('axiosCall', apiUrl + '/' + path);
  return new Promise((resolve, reject) => {
    getAccessTokenSilently().then(
      (token: string) => {
        axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        axios({
          url: apiUrl + '/' + path,
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
            ['Content-Type']: 'application/json',
            ...additionalHeaders,
          },
          data: payload === undefined ? undefined : payload,
        })
          .then((response: { data: T }) => {
            resolve(response.data as T);
          })
          .catch((error: Error) => {
            reject(error);
          });
      },
      err => {
        reject(err);
      },
    );
  });
};
