import { axiosCall } from '../../constants';
import { ApplicationModel } from '../models';

export const getApplications = async (
  getAccessTokenSilently: CallableFunction,
): Promise<ApplicationModel[]> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<ApplicationModel[]>(getAccessTokenSilently, 'applications')
      .then((applications: ApplicationModel[]) => {
        resolve(applications);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

export const getApplication = async (
  getAccessTokenSilently: CallableFunction,
  applicationId: number,
): Promise<ApplicationModel> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<ApplicationModel>(
      getAccessTokenSilently,
      'applications/' + applicationId,
    )
      .then((applications: ApplicationModel) => {
        resolve(applications);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
