import './ApplicationModelForm.css';
import {
  ApplicationModel,
  autocomplete,
  autocompleteNoclientId,
  createApplication,
  deleteApplication,
  updateApplication,
  UserModel,
} from '../../api';
import React, { SyntheticEvent, useState } from 'react';
import {
  ApplicationFormModel,
  applicationFormStore,
  applicationsStore,
  applicationStore,
  resetApplicationFormStore,
  sendToaster,
  setApplicationFormStoreAdministratorsEmails,
  setApplicationFormStoreClientId,
  setApplicationFormStoreRessourceId,
  ToasterState,
  toasterStore,
} from '../../stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { buttonStyle } from '../../styles';
import { router } from '../../router';
import { Add, Remove } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { toastErrorFromApiCatch } from '../../helpers';

export interface ApplicationModelFormProps {
  mode: 'update' | 'delete' | 'create';
}

export const ApplicationModelForm = (props: ApplicationModelFormProps) => {
  const mode: 'update' | 'delete' | 'create' = props.mode;

  /*HOOKS*/
  const { getAccessTokenSilently } = useAuth0();
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const setApplication: SetterOrUpdater<ApplicationModel | null> =
    useSetRecoilState(applicationStore);
  const setApplications: SetterOrUpdater<ApplicationModel[]> =
    useSetRecoilState(applicationsStore);
  const [administratorsEmailsTextField, setadministratorsEmailsTextField] =
    useState<string>('');
  const applicationFormValues: ApplicationFormModel =
    useRecoilValue(applicationFormStore);
  const setRecoilApplicationForm: SetterOrUpdater<ApplicationFormModel> =
    useSetRecoilState(applicationFormStore);
  const setRecoilToaster: SetterOrUpdater<ToasterState> =
    useSetRecoilState(toasterStore);
  const [options, setOptions] = useState<UserModel[]>([]);
  const [inputValue, setInputValue] = useState('');
  /*HOOKS*/

  /*HANDLERS*/
  const handleSelectionChange = (event, _user: UserModel) => {
    if (!_user) return;
    event.preventDefault();
    if (!_user.email) return;
    if (applicationFormValues.administratorsEmails.includes(_user.auth0Id))
      return;
    if (_user.email.trim().length === 0) return;
    const currentAdmins: string[] = applicationFormValues.administratorsEmails;
    setApplicationFormStoreAdministratorsEmails(
      applicationFormValues,
      setRecoilApplicationForm,
      [...currentAdmins, _user.email],
    );
    setadministratorsEmailsTextField('');
    setOptions([]);
    setInputValue('');
  };
  const handleCreate = async e => {
    e.preventDefault();
    try {
      const applications: ApplicationModel[] = await createApplication(
        getAccessTokenSilently,
        applicationFormValues.clientId,
        applicationFormValues.ressourceId,
        applicationFormValues.administratorsEmails,
      );
      setApplications(applications);
      resetApplicationFormStore(setRecoilApplicationForm);
      router.navigate(`/applications`);
      sendToaster('Application has been created', 'success', setRecoilToaster);
    } catch (e) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };
  const handleUpdate = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      const applications: ApplicationModel[] = await updateApplication(
        getAccessTokenSilently,
        application.id,
        applicationFormValues.clientId,
        applicationFormValues.ressourceId,
        applicationFormValues.administratorsEmails,
      );
      setApplications(applications);
      resetApplicationFormStore(setRecoilApplicationForm);
      router.navigate(`/applications`);
      sendToaster('Application has been updated', 'success', setRecoilToaster);
    } catch (e) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleDelete = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      const applications: ApplicationModel[] = await deleteApplication(
        getAccessTokenSilently,
        application.id,
      );
      setApplications(applications);
      resetApplicationFormStore(setRecoilApplicationForm);
      router.navigate(`/applications`);
      sendToaster('Application has been updated', 'success', setRecoilToaster);
    } catch (e) {
      toastErrorFromApiCatch(e, setRecoilToaster);
    }
  };

  const handleAutocompleteChange = (
    event: SyntheticEvent,
    newInputValue: string,
  ) => {
    event.preventDefault();
    setInputValue(newInputValue);
    if (inputValue && inputValue.length > 1) {
      if (application) {
        autocomplete(getAccessTokenSilently, application?.clientId, inputValue)
          .then((users: UserModel[]) => {
            setOptions(users);
          })
          .catch(error => {
            console.error('Error fetching autocomplete data:', error);
          });
      } else {
        autocompleteNoclientId(getAccessTokenSilently, inputValue)
          .then((users: UserModel[]) => {
            setOptions(users);
          })
          .catch(error => {
            console.error('Error fetching autocomplete data:', error);
          });
      }
    } else {
      setOptions([]);
    }
  };
  /*HANDLERS*/

  if (mode === 'delete' && !application) return <></>;
  if (mode === 'update' && !application) return <></>;

  let button = <></>;

  if (mode === 'delete' && application) {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleDelete}>
        Delete
      </Button>
    );
  } else if (mode === 'update' && application) {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleUpdate}>
        Update
      </Button>
    );
  } else {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleCreate}>
        Create
      </Button>
    );
  }

  return (
    <div className="ApplicationModelForm">
      <form method={'POST'}>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <TextField
              disabled={mode === 'delete'}
              value={applicationFormValues.clientId}
              onChange={event => {
                event.preventDefault();
                setApplicationFormStoreClientId(
                  applicationFormValues,
                  setRecoilApplicationForm,
                  event.target.value,
                );
              }}
              id="standard-basic"
              label="Client ID"
              variant="standard"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <TextField
              disabled={mode === 'delete'}
              value={applicationFormValues.ressourceId}
              onChange={event => {
                event.preventDefault();
                setApplicationFormStoreRessourceId(
                  applicationFormValues,
                  setRecoilApplicationForm,
                  event.target.value,
                );
              }}
              id="standard-basic"
              label="Ressource ID"
              variant="standard"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              disablePortal
              id="user-autocomplete"
              options={options}
              onChange={handleSelectionChange}
              getOptionLabel={option => option.email}
              sx={{ width: 300 }}
              onInputChange={(event: SyntheticEvent, newInputValue: string) => {
                handleAutocompleteChange(event, newInputValue);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField {...params} label="Search user" />
              )}
            />
          </FormControl>
          <FormControl
            sx={{ m: 1, width: 300 }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
            }}
          >
            <TextField
              style={{ width: 320 }}
              disabled={mode === 'delete'}
              value={administratorsEmailsTextField}
              onChange={event => {
                event.preventDefault();
                setadministratorsEmailsTextField(event.target.value);
              }}
              id="standard-basic"
              label="New administrators emails"
              variant="standard"
            />
            {mode === 'delete' ? (
              <></>
            ) : (
              <Add
                style={{ fontSize: 30, marginLeft: 10, cursor: 'pointer' }}
                color="primary"
                onClick={() => {
                  if (!administratorsEmailsTextField) return;
                  if (
                    applicationFormValues.administratorsEmails.includes(
                      administratorsEmailsTextField,
                    )
                  )
                    return;
                  if (administratorsEmailsTextField.trim().length === 0) return;
                  const currentAdmins: string[] =
                    applicationFormValues.administratorsEmails;
                  setApplicationFormStoreAdministratorsEmails(
                    applicationFormValues,
                    setRecoilApplicationForm,
                    [...currentAdmins, administratorsEmailsTextField],
                  );
                  setadministratorsEmailsTextField('');
                }}
              />
            )}
          </FormControl>
        </div>
        <List style={{ paddingLeft: 0 }} dense={true}>
          {applicationFormValues.administratorsEmails.map(
            (administratorAuthZeroId: string, idx: number) => {
              return (
                <ListItem
                  key={`administratorAuthZeroId_${idx}`}
                  style={{ paddingLeft: 8, width: 320 }}
                >
                  <ListItemText primary={administratorAuthZeroId} />
                  <IconButton edge="end" aria-label="delete">
                    {mode === 'delete' ? (
                      <></>
                    ) : (
                      <Remove
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let currentAdmins: string[] =
                            applicationFormValues.administratorsEmails;
                          const index = currentAdmins.indexOf(
                            administratorAuthZeroId,
                          );
                          if (index > -1) {
                            currentAdmins = currentAdmins.filter(
                              (_, i) => i !== index,
                            );
                          }
                          setApplicationFormStoreAdministratorsEmails(
                            applicationFormValues,
                            setRecoilApplicationForm,
                            [...currentAdmins],
                          );
                        }}
                      />
                    )}
                  </IconButton>
                </ListItem>
              );
            },
          )}
        </List>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            {button}
            <br />
            <Button
              style={buttonStyle}
              onClick={() => {
                router.navigate(`/`);
                setApplication(null);
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </FormControl>
        </div>
      </form>
    </div>
  );
};
