import { createBrowserRouter } from 'react-router-dom';
import { Home } from './Home/Home';
import { ProxyPage } from './shared';
import React from 'react';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />, // Home Page
  },
  {
    path: 'applications',
    element: (
      <ProxyPage
        title={'Applications'}
        pageType={'listing'}
        entityType={'application'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId',
    element: (
      <ProxyPage
        title={'Application'}
        pageType={'listing'}
        entityType={'user'}
      />
    ), // View Page
  },
  {
    path: 'application/create',
    element: (
      <ProxyPage
        title={'Create application'}
        pageType={'form'}
        entityType={'application'}
        formType={'create'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/edit',
    element: (
      <ProxyPage
        title={'Edit application'}
        pageType={'form'}
        entityType={'application'}
        formType={'update'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/delete',
    element: (
      <ProxyPage
        title={'Delete application'}
        pageType={'form'}
        entityType={'application'}
        formType={'delete'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/users',
    element: (
      <ProxyPage
        title={'Users of application'}
        pageType={'listing'}
        entityType={'user'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/users/:q/:page',
    element: (
      <ProxyPage
        title={'Users of application'}
        pageType={'listing'}
        entityType={'user'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/users/:page',
    element: (
      <ProxyPage
        title={'Users of application'}
        pageType={'listing'}
        entityType={'user'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/user/:userId',
    element: <ProxyPage title={'User'} pageType={'view'} entityType={'user'} />, // View Page
  },
  {
    path: 'application/:applicationId/user/create',
    element: (
      <ProxyPage
        title={'Create user'}
        pageType={'form'}
        entityType={'user'}
        formType={'create'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/user/:userId/edit',
    element: (
      <ProxyPage
        title={'User edit'}
        pageType={'form'}
        entityType={'user'}
        formType={'update'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/user/:userId/delete',
    element: (
      <ProxyPage
        title={'User delete'}
        pageType={'form'}
        entityType={'user'}
        formType={'delete'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/roles',
    element: (
      <ProxyPage
        title={'Roles of application'}
        pageType={'listing'}
        entityType={'role'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/role/:roleId',
    element: (
      <ProxyPage
        title={'Role of application'}
        pageType={'view'}
        entityType={'role'}
      />
    ), // View Page
  },
  {
    path: 'application/:applicationId/role/create',
    element: (
      <ProxyPage
        title={'Create role'}
        pageType={'form'}
        entityType={'role'}
        formType={'create'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/role/:roleId/edit',
    element: (
      <ProxyPage
        title={'Edit role'}
        pageType={'form'}
        entityType={'role'}
        formType={'update'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/role/:roleId/delete',
    element: (
      <ProxyPage
        title={'Delete role'}
        pageType={'form'}
        entityType={'role'}
        formType={'delete'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/role/:roleId/permissions',
    element: (
      <ProxyPage
        title={'permissions of role'}
        pageType={'listing'}
        entityType={'permission'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/role/:roleId/permission/:permissionId',
    element: (
      <ProxyPage
        title={'permission of role'}
        pageType={'view'}
        entityType={'permission'}
      />
    ), // View Page
  },
  {
    path: 'application/:applicationId/role/:roleId/permission/create',
    element: (
      <ProxyPage
        title={'Create permission'}
        pageType={'form'}
        entityType={'permission'}
        formType={'create'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/role/:roleId/permission/:permissionId/edit',
    element: (
      <ProxyPage
        title={'Edit permission'}
        pageType={'form'}
        entityType={'permission'}
        formType={'update'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/role/:roleId/permission/:permissionId/delete',
    element: (
      <ProxyPage
        title={'Delete permission'}
        pageType={'form'}
        entityType={'permission'}
        formType={'delete'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/permissions',
    element: (
      <ProxyPage
        title={'permissions of application'}
        pageType={'listing'}
        entityType={'applicationPermissions'}
      />
    ), // Listing Page
  },
  {
    path: 'application/:applicationId/permission/create',
    element: (
      <ProxyPage
        title={'Create permission on application'}
        formType={'create'}
        pageType={'form'}
        entityType={'applicationPermissions'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/permission/:permissionId/edit',
    element: (
      <ProxyPage
        title={'Edit permission on application'}
        formType={'update'}
        pageType={'form'}
        entityType={'applicationPermissions'}
      />
    ), // Form Page
  },
  {
    path: 'application/:applicationId/permission/:permissionId/delete',
    element: (
      <ProxyPage
        title={'Delete permission on application'}
        formType={'delete'}
        pageType={'form'}
        entityType={'applicationPermissions'}
      />
    ), // Form Page
  },
]);
