import { axiosCall, clientIdHeaderName } from '../../constants';
import { UserModel } from '../models';

export const removeUser = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  userId: number,
): Promise<UserModel[]> => {
  return axiosCall<UserModel[]>(
    getAccessTokenSilently,
    'users/' + userId,
    'delete',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
