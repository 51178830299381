import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { ApplicationModel, createApplication, deleteApplication } from '../api';
import { loadApplications } from './applications.store';
import { plainToInstance } from 'class-transformer';

export const applicationStore: RecoilState<ApplicationModel | null> =
  atom<ApplicationModel | null>({
    key: 'applicationStore',
    default: null,
  });
// currentState: ApplicationFormModel, setRecoil: SetterOrUpdater<ApplicationFormModel> setRecoil: SetterOrUpdater<>,
export const deleteApplicationHandler = async (
  getAccessTokenSilently: CallableFunction,
  setRecoil: SetterOrUpdater<ApplicationModel[]>,
  applicationId: number,
): Promise<ApplicationModel[]> => {
  const applications: ApplicationModel[] = await deleteApplication(
    getAccessTokenSilently,
    applicationId,
  );
  setRecoil(plainToInstance(ApplicationModel, applications));
  return applications;
};

export const createApplicationHandler = async (
  getAccessTokenSilently: CallableFunction,
  setRecoil: SetterOrUpdater<ApplicationModel[]>,
  clientId: string,
  resourceId: string,
  administrators: string[],
): Promise<ApplicationModel[]> => {
  const applications: ApplicationModel[] = await createApplication(
    getAccessTokenSilently,
    clientId,
    resourceId,
    administrators,
  );
  await loadApplications(getAccessTokenSilently, setRecoil);
  return applications;
};
