import { RoleModel } from './role.model';
import { Type } from 'class-transformer';

export class PermissionModel {
  id: number;

  name: string;

  description: string;

  @Type(() => RoleModel)
  roles: RoleModel[];

  @Type(() => RoleModel)
  __roles__: RoleModel[];
}
