import { axiosCall } from '../../constants';
import { plainToInstance } from 'class-transformer';
import { ApplicationModel } from '../models';
import { createApplicationRequestDto } from '../dto';

export const updateApplication = async (
  getAccessTokenSilently: CallableFunction,
  applicationId: number | null,
  clientId: string,
  resourceId: string,
  administrators: string[],
): Promise<ApplicationModel[]> => {
  return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
    axiosCall<ApplicationModel[]>(
      getAccessTokenSilently,
      'applications/' + applicationId,
      'patch',
      plainToInstance(createApplicationRequestDto, {
        clientId: clientId,
        resourceId: resourceId,
        administratorsEmails: administrators,
      }),
    )
      .then((applications: ApplicationModel[]) => {
        resolve(applications);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
