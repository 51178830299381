import { RoleModel } from './role.model';
import { ApplicationModel } from './application.model';
import { Type } from 'class-transformer';

export class UserModel {
  id: number;
  auth0Id: string;
  email: string;
  password: string;
  emailVerified: boolean;

  @Type(() => RoleModel)
  roles: RoleModel[];
  isDashboardAdmin = false;

  canAdmin(clientId: string) {
    return this.roles.some((role: RoleModel) => {
      return role.application.clientId === clientId && role.name === 'admin';
    });
  }

  hasDefaultRoleForApplication(clientId: string) {
    return this.roles.some((role: RoleModel) => {
      return role.application.clientId === clientId && role.name === 'user';
    });
  }

  get isAdminOf(): string[] {
    return this.roles
      .filter((role: RoleModel) => {
        return role.application.clientId && role.name === 'admin';
      })
      .map((role: RoleModel) => {
        return role.application.clientId;
      });
  }

  get applications(): ApplicationModel[] {
    return this.roles
      .map((role: RoleModel) => {
        return role.application;
      })
      .reduce((applications: ApplicationModel[], app: ApplicationModel) => {
        if (
          !applications.some((_app: ApplicationModel) => _app.id === app.id)
        ) {
          applications.push(app);
        }
        return applications;
      }, []);
  }

  hasAccessToApplication(app: ApplicationModel) {
    return this.applications.some((_app: ApplicationModel) => {
      return _app.id === app.id;
    });
  }

  get numberOfApplications(): number {
    return this.applications.length;
  }
}
