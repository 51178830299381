import React from 'react';
import './UserOverview.css';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationModel, UserModel } from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { applicationStore, loadUser, selectedUserStore } from '../../stores';
import { useEffect } from 'react';

export const UserOverview = () => {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();

  if (!params.userId) throw new Error('User is required');
  const userId = parseInt(params.userId);

  const user: UserModel | null = useRecoilValue(selectedUserStore);
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const setRecoilUser: SetterOrUpdater<UserModel | null> =
    useSetRecoilState(selectedUserStore);

  useEffect(() => {
    if (!user)
      loadUser(getAccessTokenSilently, application, setRecoilUser, userId);
  }, [user]);

  return <div>UserOverview</div>;
};
