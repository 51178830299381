import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { DashboardHeader } from './Header';
import { router } from './router';
import { Loader } from './Loader';
import { Toaster } from './Toaster/Toaster';
import { useAuth0 } from '@auth0/auth0-react';
import { loadWhoiam, userStore } from './stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { UserWhoiamDto } from './api';

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
    loginWithRedirect,
  } = useAuth0();

  const dashboardUser: UserWhoiamDto | null = useRecoilValue(userStore);
  const setRecoilDashboardUser: SetterOrUpdater<UserWhoiamDto | null> =
    useSetRecoilState(userStore);

  const style = {
    container: {
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      textAlign: 'left',
    },
    header: {
      fontSize: '24px',
      color: '#333',
    },
    subHeader: {
      fontSize: '18px',
      color: '#666',
      marginTop: '10px',
    },
    paragraph: {
      fontSize: '16px',
      color: '#444',
      marginTop: '10px',
      lineHeight: '1.6',
    },
    button: {
      marginTop: '20px',
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#fff',
      color: '#000',
      border: 'solid 1px #000',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      loadWhoiam(getAccessTokenSilently, setRecoilDashboardUser);
    }
  }, [isAuthenticated]);

  if (!isLoading && dashboardUser) {
    return (
      <>
        <DashboardHeader />
        <div className={'content'}>
          <Loader />
          <Toaster />
          <RouterProvider router={router} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <DashboardHeader />
        <div
          style={{
            fontFamily: 'Arial, sans-serif',
            padding: '20px',
            textAlign: 'left',
          }}
        >
          <h1 style={style.header}>Welcome to Vallourec's Dashboard</h1>
          <h2 style={style.subHeader}>Secure Access, Streamlined Management</h2>
          <p style={style.paragraph}>
            At Vallourec, we combine cutting-edge technology with uncompromised
            security to manage our digital ecosystem. Our centralized dashboard,
            empowered by Auth0, is a testament to this dedication.
          </p>
          {/* ... additional content ... */}
          <p style={style.paragraph}>
            To explore the full capabilities of Vallourec's dashboard, please
            sign in with your Auth0 credentials. For support or assistance, our
            dedicated team is always ready to assist you.
          </p>
          <button
            onClick={() => {
              loginWithRedirect();
            }}
            style={style.button}
          >
            Sign In to Learn More
          </button>
        </div>
      </>
    );
  }
};
export default App;

//
