import { axiosCall, clientIdHeaderName } from '../../constants';
import { PermissionModel } from '../models';

export const getAllPermissions = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
): Promise<PermissionModel[]> => {
  return axiosCall<PermissionModel[]>(
    getAccessTokenSilently,
    'permissions',
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
