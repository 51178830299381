import { axiosCall, clientIdHeaderName } from '../../constants';
import { UserModel } from '../models';

export const getAllUsers = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
): Promise<UserModel[]> => {
  return axiosCall<UserModel[]>(
    getAccessTokenSilently,
    'users',
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
