import React from 'react';
import './Breadcrumb.css';
import { Breadcrumbs } from '@mui/material';
import { Link, Typography } from '@mui/material';
import {
  ApplicationModel,
  PermissionModel,
  RoleModel,
  UserModel,
} from '../api';
import { useRecoilValue } from 'recoil';
import {
  applicationStore,
  selectedPermissionsStore,
  selectedRoleStore,
  selectedUserStore,
} from '../stores';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { capitalizeFirstLetter } from '../helpers';

export interface BreadcrumbItem {
  label: string;
  link: string;
}

export const Breadcrumb = () => {
  const location = useLocation();

  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const user: UserModel | null = useRecoilValue(selectedUserStore);
  const role: RoleModel | null = useRecoilValue(selectedRoleStore);
  const permission: PermissionModel | null = useRecoilValue(
    selectedPermissionsStore,
  );

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
  const [currentPageName, setCurrentPageName] = useState<string>('');

  const setBreadcrumb: CallableFunction = () => {
    if (application !== null) {
      if (/^\/application\/\d+$/.test(window.location.pathname)) {
        setCurrentPageName(application.name + ' users');
      } else if (/^\/application\/\d+\/edit$/.test(window.location.pathname)) {
        setCurrentPageName('Edit ' + application.name);
      } else if (
        /^\/application\/\d+\/delete$/.test(window.location.pathname)
      ) {
        setCurrentPageName('Delete ' + application.name);
      } else if (/^\/application\/\d+\/roles$/.test(window.location.pathname)) {
        setCurrentPageName(application.name + ' roles');
      } else if (/^\/application\/\d+\/users$/.test(window.location.pathname)) {
        setCurrentPageName(application.name + ' users');
      } else if (
        /^\/application\/\d+\/user\/create$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          { label: application.name, link: `/application/${application.id}` },
          { label: 'users', link: `/application/${application.id}/users` },
        ]);
        setCurrentPageName('create user');
      } else if (
        /^\/application\/\d+\/user\/\d+\/edit$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          { label: application.name, link: `/application/${application.id}` },
          { label: 'users', link: `/application/${application.id}/users` },
        ]);
        if (user !== null) {
          setCurrentPageName('edit ' + user.email);
        }
      } else if (
        /^\/application\/\d+\/user\/\d+\/delete$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          { label: application.name, link: `/application/${application.id}` },
          { label: 'users', link: `/application/${application.id}/users` },
        ]);
        if (user !== null) {
          setCurrentPageName('delete ' + user.email);
        }
      } else if (
        /^\/application\/\d+\/role\/create$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/roles`,
          },
          // {label: 'roles', link: `/application/${application.id}/roles`},
        ]);
        setCurrentPageName('create role on application ' + application.name);
      } else if (
        /^\/application\/\d+\/role\/\d+\/edit$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/roles`,
          },
          // {label: 'roles', link: `/application/${application.id}/roles`},
        ]);
        if (role !== null) {
          setCurrentPageName(
            'edit ' + role.name + ' role on application ' + application.name,
          );
        }
      } else if (
        /^\/application\/\d+\/role\/\d+\/delete$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          { label: application.name, link: `/application/${application.id}` },
          { label: 'roles', link: `/application/${application.id}/roles` },
        ]);
        if (role !== null) {
          setCurrentPageName(
            'delete ' + role.name + ' role on application ' + application.name,
          );
        }
      } else if (
        /^\/application\/\d+\/role\/\d+\/permissions$/.test(
          window.location.pathname,
        )
      ) {
        ////////////////////////////
        if (role !== null) {
          setBreadcrumbItems([
            { label: application.name, link: `/application/${application.id}` },
            {
              label: role.name,
              link: `/application/${application.id}/role/${role.id}`,
            },
            // {label: 'roles', link: `/application/${application.id}/roles`},
            // {label: role.name, link: `/application/${application.id}/role/${role.id}`},
          ]);
          setCurrentPageName(
            'permissions of role ' +
              role.name +
              ' on application ' +
              application.name,
          );
        }
      } else if (
        /^\/application\/\d+\/role\/\d+\/permission\/create$/.test(
          window.location.pathname,
        )
      ) {
        if (role !== null) {
          setBreadcrumbItems([
            {
              label: application.name,
              link: `/application/${application.id}/permissions`,
            },
            // {label: 'roles', link: `/application/${application.id}/roles`},
            //{label: role.name, link: `/application/${application.id}/role/${role.id}`},
          ]);
          setCurrentPageName(
            'Create permission on role ' +
              role.name +
              ' for application ' +
              application.name,
          );
        }
      } else if (
        /^\/application\/\d+\/role\/\d+\/permission\/\d+\/edit$/.test(
          window.location.pathname,
        )
      ) {
        if (role !== null) {
          setBreadcrumbItems([
            {
              label: application.name,
              link: `/application/${application.id}/permissions`,
            },
            // {label: 'roles', link: `/application/${application.id}/roles`},
            //{label: role.name, link: `/application/${application.id}/role/${role.id}`},
          ]);
          if (permission !== null) {
            setCurrentPageName(
              'Edit permission ' +
                permission.name +
                ' on role ' +
                role.name +
                ' for application ' +
                application.name,
            );
          }
        }
      } else if (
        /^\/application\/\d+\/role\/\d+\/permission\/\d+\/delete$/.test(
          window.location.pathname,
        )
      ) {
        if (role !== null) {
          setBreadcrumbItems([
            { label: application.name, link: `/application/${application.id}` },
            // {label: 'roles', link: `/application/${application.id}/roles`},
            // {label: role.name, link: `/application/${application.id}/role/${role.id}`},
          ]);
          if (permission !== null) {
            setCurrentPageName(
              'Delete permission ' +
                permission.name +
                ' on role ' +
                role.name +
                ' for application ' +
                application.name,
            );
          }
        }
      } else if (
        /^\/application\/\d+\/permissions$/.test(window.location.pathname)
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/users`,
          },
          // {label: role.name, link: `/application/${application.id}/role/${role.id}`},
        ]);
        setCurrentPageName(application.name + ' permissions');
      } else if (
        /^\/application\/\d+\/permission\/create$/.test(
          window.location.pathname,
        )
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/permissions`,
          },
          { label: 'roles', link: `/application/${application.id}/roles` },
        ]);
        setCurrentPageName(application.name + ' create permission');
      } else if (
        /^\/application\/\d+\/permission\/\d+\/edit$/.test(
          window.location.pathname,
        )
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/permissions`,
          },
          { label: 'roles', link: `/application/${application.id}/roles` },
        ]);
        setCurrentPageName(application.name + ' edit permission');
      } else if (
        /^\/application\/\d+\/permission\/\d+\/delete$/.test(
          window.location.pathname,
        )
      ) {
        setBreadcrumbItems([
          {
            label: application.name,
            link: `/application/${application.id}/permissions`,
          },
          { label: 'roles', link: `/application/${application.id}/roles` },
        ]);
        setCurrentPageName(application.name + ' delete permission');
      } else {
        setBreadcrumbItems([]);
        setCurrentPageName('');
      }
    } else {
      if (window.location.pathname === '/') {
        setCurrentPageName('My applications');
      } else if (/^\/applications$/.test(window.location.pathname)) {
        setCurrentPageName('My applications');
      } else if (window.location.pathname === '/application/create') {
        setCurrentPageName('Create new applications');
      } else {
        setBreadcrumbItems([]);
        setCurrentPageName('');
      }
    }
  };
  useEffect(() => {
    setBreadcrumb();
  }, [
    window.location.pathname,
    application,
    user,
    role,
    permission,
    location.pathname,
  ]);

  return (
    <div className={'dashboarrd-breadcrumb'}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        {breadcrumbItems.map((breadcrumbItem: BreadcrumbItem, idx: number) => {
          return (
            <Link
              key={`breadcrumb_item_${idx}`}
              underline="hover"
              color="inherit"
              href={breadcrumbItem.link}
            >
              {capitalizeFirstLetter(breadcrumbItem.label)}
            </Link>
          );
        })}
        <Typography color="text.primary">
          {capitalizeFirstLetter(currentPageName)}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};
