import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export class RoleFormModel {
  name: string;
  description: string;
  nameError = '';
  descriptionError = '';
}

export const defaultRoleFormModel: RoleFormModel = {
  name: '',
  description: '',
  nameError: '',
  descriptionError: '',
};

export const roleFormStore: RecoilState<RoleFormModel> = atom<RoleFormModel>({
  key: 'roleFormStore',
  default: defaultRoleFormModel,
});

export const setRoleFormStoreName = (
  currentState: RoleFormModel,
  setRecoil: SetterOrUpdater<RoleFormModel>,
  name: string,
) => {
  setRecoil({ ...currentState, name });
};

export const setRoleFormStoreDescription = (
  currentState: RoleFormModel,
  setRecoil: SetterOrUpdater<RoleFormModel>,
  description: string,
) => {
  setRecoil({ ...currentState, description });
};

export const setRoleFormStoreNameError = (
  currentState: RoleFormModel,
  setRecoil: SetterOrUpdater<RoleFormModel>,
  nameError: string,
) => {
  setRecoil({ ...currentState, nameError });
};

export const setRoleFormStoreDescriptionError = (
  currentState: RoleFormModel,
  setRecoil: SetterOrUpdater<RoleFormModel>,
  descriptionError: string,
) => {
  setRecoil({ ...currentState, descriptionError });
};

export const resetRoleFormStore = (
  setRecoil: SetterOrUpdater<RoleFormModel>,
) => {
  setRecoil(defaultRoleFormModel);
};

export const setRoleFormStoreValues = (
  setRecoil: SetterOrUpdater<RoleFormModel>,
  values: RoleFormModel,
) => {
  setRecoil(values);
};
