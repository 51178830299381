import React from 'react';
import './ApplicationOverview.css';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationModel } from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { applicationStore, loadApplication } from '../../stores';
import { useEffect } from 'react';

export const ApplicationOverview = () => {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();

  if (!params.applicationId) throw new Error('ApplicationId is required');
  const applicationId = parseInt(params.applicationId);

  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const setRecoilApplication: SetterOrUpdater<ApplicationModel | null> =
    useSetRecoilState(applicationStore);

  useEffect(() => {
    if (!application)
      loadApplication(
        getAccessTokenSilently,
        setRecoilApplication,
        applicationId,
      );
  }, [application]);

  return <div>ApplicationOverview</div>;
};
