import { axiosCall, clientIdHeaderName } from '../../constants';
import { RoleModel } from '../models';

export const getAllRoles = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
): Promise<RoleModel[]> => {
  return axiosCall<RoleModel[]>(
    getAccessTokenSilently,
    'roles',
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
