import React from 'react';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { applicationsStore, loadApplications, userStore } from '../stores';
import { ListingPage } from '../shared';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { goto } from '../helpers';
import { ApplicationModel } from '../api';

export const Home = () => {
  const { getAccessTokenSilently } = useAuth0();

  const dashboardUser = useRecoilValue(userStore);
  const setRecoilApplications: SetterOrUpdater<ApplicationModel[]> =
    useSetRecoilState(applicationsStore);

  useEffect(() => {
    if (
      dashboardUser &&
      (dashboardUser.isDashboardAdmin || dashboardUser.adminOf.length > 1)
    ) {
      loadApplications(getAccessTokenSilently, setRecoilApplications);
    } else {
      if (dashboardUser && dashboardUser.adminOf.length === 1) {
        goto(`/application/${dashboardUser.adminOfIds[0]}/users`);
      }
    }
  }, [dashboardUser]);

  if (!dashboardUser) {
    return <></>;
  }

  if (dashboardUser.isDashboardAdmin || dashboardUser.adminOf.length > 1) {
    return <ListingPage entityType={'application'} />;
  } else {
    return <ListingPage entityType={'user'} />;
  }
};
