import { atom, RecoilState } from 'recoil';
import { plainToInstance } from 'class-transformer';

export const perPage = 10;

export class PaginationStoreModel {
  total: number;
  page: number;
}

export const defaultPaginationStoreModel: PaginationStoreModel =
  plainToInstance(PaginationStoreModel, {
    total: 0,
    page: 1,
  });

export const paginationStore: RecoilState<PaginationStoreModel> =
  atom<PaginationStoreModel>({
    key: 'paginationStore',
    default: defaultPaginationStoreModel,
  });
