import { axiosCall, clientIdHeaderName } from '../../constants';
import { UserModel } from '../models';
import { plainToInstance } from 'class-transformer';
import { UpdateRolesDto } from './dto';

export const updateRoles = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  userId: number,
  rolesIds: number[],
): Promise<UserModel> => {
  return axiosCall<UserModel>(
    getAccessTokenSilently,
    'users/' + userId + '/update-roles',
    'post',
    plainToInstance(UpdateRolesDto, {
      rolesIds,
    }),
    { [clientIdHeaderName]: clientId },
  );
};
