import { axiosCall, clientIdHeaderName } from '../../constants';
import { UsersPaginatedResultDto } from './dto';

export const searchAllUsersPaginated = async (
  getAccessTokenSilently: CallableFunction,
  clientId: string,
  q: string,
  page = 1,
): Promise<UsersPaginatedResultDto> => {
  return axiosCall<UsersPaginatedResultDto>(
    getAccessTokenSilently,
    'users/search/' + encodeURI(q) + '/' + page,
    'get',
    undefined,
    { [clientIdHeaderName]: clientId },
  );
};
