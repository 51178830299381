export const sidebarMenuItemStyle = {
  color: 'black',
  backgroundColor: 'white',
  textDecoration: 'none',
  width: 200,
  border: 'solid 1px #DDD',
  marginBottom: 6,
  borderRadius: 5,
};

export const sidebarMenuItemStyleActive = {
  color: 'white',
  backgroundColor: 'black',
  textDecoration: 'none',
  width: 200,
  marginBottom: 6,
  borderRadius: 5,
};
