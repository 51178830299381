import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import {
  ApplicationModel,
  createRole,
  getAllRoles,
  getOneRole,
  PermissionModel,
  removeRole,
  RoleModel,
  updateRole,
} from '../api';
import { plainToInstance } from 'class-transformer';
import { noApplicationSelected } from '../constants';

export const rolesStore: RecoilState<RoleModel[]> = atom<RoleModel[]>({
  key: 'rolesStore',
  default: [],
});

export const selectedRoleStore: RecoilState<RoleModel | null> =
  atom<RoleModel | null>({
    key: 'selectedRoleStore',
    default: null,
  });

export const loadRoles = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<RoleModel[]>,
): Promise<RoleModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const roles: RoleModel[] = await getAllRoles(
    getAccessTokenSilently,
    application.clientId,
  );
  setRecoil(plainToInstance(RoleModel, roles));
  return roles;
};

export const loadOneRole = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<RoleModel | null>,
  roleId: number | string | undefined,
): Promise<RoleModel> => {
  if (roleId === undefined) {
    throw new Error('role is undefined');
  }
  if (typeof roleId === 'string') {
    roleId = parseInt(roleId);
  }
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const role: RoleModel = await getOneRole(
    getAccessTokenSilently,
    application.clientId,
    roleId,
  );
  role.permissions = role.permissions.map((_permission: PermissionModel) => {
    _permission.roles = _permission.__roles__;
    return _permission;
  });
  setRecoil(plainToInstance(RoleModel, role));
  return role;
};

export const createRoleHandler = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<RoleModel | null>,
  setRecoilRoles: SetterOrUpdater<RoleModel[]>,
  name: string,
  description: string,
): Promise<RoleModel> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const role: RoleModel = await createRole(
    getAccessTokenSilently,
    application.clientId,
    name,
    description,
  );
  setRecoil(plainToInstance(RoleModel, role));
  await loadRoles(getAccessTokenSilently, application, setRecoilRoles);
  return role;
};

export const deleteRole = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoilRoles: SetterOrUpdater<RoleModel[]>,
  roleId: number,
): Promise<RoleModel[]> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const roles: RoleModel[] = await removeRole(
    getAccessTokenSilently,
    application.clientId,
    roleId,
  );
  setRecoilRoles(plainToInstance(RoleModel, roles));
  return roles;
};

export const updateRoleHandler = async (
  getAccessTokenSilently: CallableFunction,
  application: ApplicationModel | null,
  setRecoil: SetterOrUpdater<RoleModel | null>,
  setRecoilRoles: SetterOrUpdater<RoleModel[]>,
  roleId: number,
  name: string,
  description: string,
): Promise<RoleModel> => {
  if (!application) {
    throw new Error(noApplicationSelected);
  }
  const role: RoleModel = await updateRole(
    getAccessTokenSilently,
    application.clientId,
    name,
    description,
    roleId,
  );
  setRecoil(plainToInstance(RoleModel, role));
  await loadRoles(getAccessTokenSilently, application, setRecoilRoles);
  return role;
};
